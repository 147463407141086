import currencySetApi from './currencySet'
import hotWalletApi from './hotWallet'
import customerWithdrawalApi from './customerWithdrawal'
import cpmApi from './cpm'

export default {
    ...currencySetApi,
    ...hotWalletApi,
    ...customerWithdrawalApi,
    ...cpmApi
}