import request from '@/utils/axios'
import moment from "moment";
// import utilFun from '../util'

const cpmList = (data)=>{
    if(data && data.createTime && data.createTime.length > 0){
        let startCreateTime = moment(data.createTime[0]).format("YYYY-MM-DD HH:ss:mm")   	
        let endCreateTime = moment(data.createTime[1]).format("YYYY-MM-DD HH:ss:mm")   	
        data.startCreateTime = startCreateTime;
        data.endCreateTime = endCreateTime;
        delete data.createTime
    }
    if(data && data.updateTime && data.updateTime.length > 0){
        let startUpdateTime = moment(data.updateTime[0]).format("YYYY-MM-DD HH:ss:mm")   	
        let endUpdateTime = moment(data.updateTime[1]).format("YYYY-MM-DD HH:ss:mm")   	
        data.startUpdateTime = startUpdateTime;
        data.endUpdateTime = endUpdateTime;
        delete data.updateTime
    }
    return request({
        url: '/market/cms/coin_price/page',
        method: 'post',
        data: data
    })
}
const cpmAdd = (data)=>{
    let url = '/market/cms/coin_price/add'
    return request({
        url: url,
        method: 'post',
        data: data
    })
}
const cpmEdit = (data)=>{
    return request({
        url: '/market/cms/coin_price/edit',
        method: 'post',
        data: data
    })
}
const cpmDel = (id)=>{
    return request({
        url: '/market/cms/coin_price/del/'+id,
        method: 'DELETE'
    })
}
export default{
    cpmList,cpmAdd,cpmEdit,cpmDel
}