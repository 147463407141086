import request from '@/utils/axios'

const menuApi = (type) => {//获得路由地址
    return request({
        url: '/admin/menu',
        method: 'get',
        headers: {
            isToken: true
        },
        params: {type}
    })
}
export default{
    menuApi
}