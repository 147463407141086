import { defineStore } from "pinia";
import { languagesArray } from '@/config/language.js'
// import { useRouter } from 'vue-router'

export const configStore = defineStore('main', {
    state: () => {
        return {
            lange: sessionStorage.getItem('lang') || 'en',
            langeStr: sessionStorage.getItem('lang') || languagesArray[0],
            Number: 1,
            tagsArray: JSON.parse(sessionStorage.getItem('tagsArray')) || [],
            checkRouteId: ''
        }
    },
    getters: {
        getLangeStr(){
            return this.langeStr
        },
        getLange(){
            return this.lange
        },
        getTagsArray(){
            return this.tagsArray
        },
        getCheckRouteId(){
            return this.checkRouteId
        }
    },
    actions: {
        setLanguage(language){
            this.lange = language;
            sessionStorage.setItem('lang',language)
        },
        setTagsArray(tagsArray){
            this.tagsArray = tagsArray;
            sessionStorage.setItem('tagsArray',JSON.stringify(tagsArray))
        },
        setCheckRouteId(checkRouteId){
            this.checkRouteId = checkRouteId;
        }
    }
})