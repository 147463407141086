import request from '@/utils/axios'
import { parmFun } from '@/utils/record'

const getTopUpNft = (params) => {//商户充值记录
    parmFun(params)
    return request({
        url: '/assets/assetsdepositrecord/pageByMerNFT',
        method: 'get',
        params: params
    })
}
const pageByMerNft = (params) => {//商户提现记录
    parmFun(params)
    return request({
        url: '/assets/withdrawapply/pageByMerNFT',
        method: 'get',
        params: params
    })
}
const getAggregationListNft = (params) => {//商户归集记录
    parmFun(params)
    return request({
        url: '/assets/colletionrecord/pageByMerNFT',
        method: 'get',
        params: params
    })
}
const getWalletWaterNft = (params) => {//钱包流水记录
    parmFun(params)
    return request({
        url: '/assets/walletchangelog/pageByMerNFT',
        method: 'get',
        params: params
    })
}
const getAccountWaterNft = (params) => {//帐户流水
    parmFun(params)
    return request({
        url: '/assets/accountchangelog/pageByMerNFT',
        method: 'get',
        params: params
    })
}
const getBalanceWalletNft = (params) => {//钱包余额
    parmFun(params)
    return request({
        url: '/assets/assetswalletinfo/pageByMerNFT',
        method: 'get',
        params: params
    })
}
const getBbalanceAccountNft = (params) => {//账户余额
    parmFun(params)
    return request({
        url: '/assets/assetsaccountinfo/pageByMerNFT',
        method: 'get',
        params: params
    })
}
const getNftmintinfo = (params) => {//nft作品记录
    parmFun(params)
    return request({
        url: '/assets/nftmintinfo/pageByMer',
        method: 'get',
        params: params
    })
}
const getNftdeployinfo = (params) => {//nft系列记录
    parmFun(params)
    return request({
        url: '/assets/nftdeployinfo/pageByMer',
        method: 'get',
        params: params
    })
}
const pageByMerNFTBatch = (params) => {//nft系列记录
    parmFun(params)
    return request({
        url: '/assets/withdrawapply/pageByMerNFTBatch',
        method: 'get',
        params: params
    })
}

export default{
    getTopUpNft,pageByMerNft,getAggregationListNft,getBbalanceAccountNft,
    getBalanceWalletNft,getAccountWaterNft,getWalletWaterNft,
    getNftmintinfo,getNftdeployinfo,pageByMerNFTBatch
}