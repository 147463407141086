import request from '@/utils/axios'

const monitorAlarmRecordPage = (data)=>{
    data.pageNum = data.current
    data.pageSize = data.size
    if(data.alarmTime){
        data.startAlarmTime = new Date(data.alarmTime[0]).getTime()
        data.endAlarmTime = new Date(data.alarmTime[1]).getTime()
    }
    return request({
        url: '/dms/cms/monitor_alarm/record/page',
        method: 'post',
        data
    })
}
const monitorAlarmRecordPageEdit = (data)=>{
    return request({
        url: '/dms/cms/monitor_alarm/record/edit',
        method: 'post',
        data
    })
}
const monitorAlarmRecordPageDetails = (id)=>{
    return request({
        url: '/dms/cms/monitor_alarm/record/detail/'+ id,
        method: 'post',
    })
}
export default{
    monitorAlarmRecordPage,monitorAlarmRecordPageEdit,monitorAlarmRecordPageDetails
}