import request from '@/utils/axios'
import moment from "moment";

const getMessTemplateList = (params) => {//message/syssmstemplate
    if(params.createTime && params.createTime.length > 0){
        params.createTime[0] = moment(params.createTime[0]).format("YYYY-MM-DD HH:ss:mm")  
        params.createTime[1] = moment(params.createTime[1]).format("YYYY-MM-DD HH:ss:mm")  
    }
    return request({
        url: '/message/syssmstemplate/page',
        method: 'get',
        params: params
    })
}
const addMessTemplateList = (obj) => {//message/syssmstemplate
    return request({
        url: '/message/syssmstemplate',
        method: 'post',
        data: obj,
    })
}
const upateMessTemplateList = (obj) => {//message/syssmstemplate
    return request({
        url: '/message/syssmstemplate',
        method: 'put',
        data: obj,
    })
}
const detalMessTemplateList = (id) => {//message/syssmstemplate
    return request({
        url: '/message/syssmstemplate/'+id,
        method: 'delete'
    })
}

export default{
    getMessTemplateList,addMessTemplateList,
    upateMessTemplateList,detalMessTemplateList
}