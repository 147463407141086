import request from '@/utils/axios'
import moment from "moment";

const getContractList = (params) => {//message/syssmstemplate
    if(params && params.chain_protocol && params.chain_protocol.length > 0){
        params.chain = params.chain_protocol[0]
        params.protocol = params.chain_protocol[1]
        delete params.chain_protocol
    }
    if(params && params.createTime && params.createTime.length > 0){
        params.beginTime = moment(params.createTime[0]).format("YYYY-MM-DD HH:ss:mm")  
        params.endTime = moment(params.createTime[1]).format("YYYY-MM-DD HH:ss:mm")  
        delete params.createTime
    }
    return request({
        url: '/chain/chaincontracttemplate/page',
        method: 'get',
        params: params
    })
}
const addContractObj = (obj) => {//添加角色
    return request({
        url: '/chain/chaincontracttemplate',
        method: 'post',
        data: obj,
    })
}
const updateContractStatus = (obj) => {//修改状态
    return request({
        url: '/chain/chaincontracttemplate/updateStatus',
        method: 'post',
        data: obj,
    })
}
const updateContractObj = (obj) => {//修改
    return request({
        url: '/chain/chaincontracttemplate',
        method: 'put',
        data: obj,
    })
}
const getCoinOrxie = (params)=>{
    return request({
        url: '/chain/chainprotocolinfo/getChainProtocolSymbol',
        method: 'get',
        params: params,
    })
}
export default{
    getContractList,addContractObj,updateContractStatus,updateContractObj,getCoinOrxie
}