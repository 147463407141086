<template>
    <el-config-provider :locale="locale">
        <router-view v-if='isRouterAlive'/>
    </el-config-provider>
</template>

<script setup>
import { ref,nextTick,provide } from 'vue'
import { configStore } from '@/store/config.js'

import elementEnLocale from 'element-plus/dist/locale/en';
import elementCnLocale from 'element-plus/dist/locale/zh-cn';
import elementTwLocale from 'element-plus/dist/locale/zh-tw';
let obj = {
    'zh': elementCnLocale,
    'en' : elementEnLocale,
    'zh_hk':elementTwLocale
}
const locale = ref(obj[configStore().getLange])
const isRouterAlive = ref(true)
const reload = ()=>{
    isRouterAlive.value = false;
    nextTick(()=>{
        locale.value = obj[configStore().getLange]
        isRouterAlive.value = true;
    })
}
provide('reload',reload)
</script>

<style lang='scss'>
</style>
