import { defineStore } from "pinia";
import addRoutesFun from '@/router/addRoutes'
import website from '@/config/website'
export const questStore = defineStore('questStore', {
    state: () => {
        return {
            token: sessionStorage.getItem('Authorization') || false,
            routesList: sessionStorage.getItem('routesList') || [],
            tagList: sessionStorage.getItem(website.key+'-tagList') || [],//顶部导航的历史记录
            tenantId: sessionStorage.getItem(website.key+'-tenantId'),//用户的商户ID
        }
    },
    getters: {
        getToken(){
            return this.token
        },
        getRoutes(){
            return this.routesList
        },
        getRoutesMeun(){
            addRoutesFun(JSON.parse(this.routesList))
            return 0
        },
        getTagList(){
            return this.tagList
        },
        getTenantId(){
            return this.tenantId
        },
    }, 
    actions: {
        setToken(token){
            this.token = token;
            sessionStorage.setItem('Authorization',token)
        },
        setRoutes(routesList){
            this.routes = routesList;
            sessionStorage.setItem('routesList',JSON.stringify(routesList))
        },
        setTenantId(tenantId){
            this.tenantId = tenantId;
            sessionStorage.setItem(website.key+'-tenantId',tenantId)
        },
        setTagList(){
            // console.log(this.tagList.length,tagList)
            // if(){

            // }
            // let tagList_ = this.tagList.push(tagList) 
            // this.tagList = tagList_;
            // sessionStorage.setItem(website.key+'-tagList',JSON.stringify(tagList_))
        }
    }
})