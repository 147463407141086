import request from '@/utils/axios'
import moment from "moment";
import utilFun from '../util'

const getAssetswalletinfoList = (params)=>{
    let url = '/assets/assetswalletinfo/page'
    params = utilFun.bindParm(params)
    if(params && params.createTimes && params.createTimes.length > 0){
        let sendTimeS = moment(params.createTimes[0]).format("YYYY-MM-DD HH:ss:mm")   	
        let sendTimeE = moment(params.createTimes[1]).format("YYYY-MM-DD HH:ss:mm")   	
        url = url + '?createTimes='+ sendTimeS+'&createTimes='+ sendTimeE;
        delete params.createTimes
    }
    return request({
        url: url,
        method: 'get',
        params: params
    })
}
const updateAssetswalletinfoList = (data)=>{
    return request({
        url: '/assets/assetswalletinfo',
        method: 'put',
        data: data
    })
}
const updateAssetswalletinfoStatus = (data)=>{
    let url = '/assets/assetswalletinfo'
    return request({
        url: url,
        method: 'put',
        data: data
    })
}
const updateAssetswalletinfoStatusView = (data)=>{
    let url = '/assets/assetswalletinfo/update'
    return request({
        url: url,
        method: 'post',
        data: data
    })
}
const addAssetswalletinfoStatus = (data,code)=>{
    let url = '/assets/assetswalletinfo/' + code
    return request({
        url: url,
        method: 'post',
        data: data
    })
}
const delAssetswalletinfoStatus = (id)=>{
    let url = '/assets/assetswalletinfo/' + id
    return request({
        url: url,
        method: 'DELETE'
    })
}
const upAssetswalletinfoStatus = (data,code)=>{
    let url = '/assets/assetswalletinfo/' + code
    return request({
        url: url,
        method: 'put',
        data: data
    })
}
export default{
    getAssetswalletinfoList,updateAssetswalletinfoList,updateAssetswalletinfoStatus,
    addAssetswalletinfoStatus,delAssetswalletinfoStatus,upAssetswalletinfoStatus,
    updateAssetswalletinfoStatusView
}