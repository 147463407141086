export default{
    currencySetNft:{
        name1:'商户',
        name2:'App名称',
        name3:'链/原生币',
        name4:'钱包名称',
        name5:'优先级分组',
        name6:'创建时间',
        name7:'修改时间',
        name8:'最后修改人',
        name9:'状态',
        name10:'原生币',

        name11_1:'开启',
        name11_2:'关闭',
        name29:'Google 验证码',

        text1:'归集',
        text2:'提现',
        text3:'新增',
        text4:'编辑',
        text5:'NFT流水',
        text6:'链',
        text7:'协议',
        text8:'操作',
    },
    otherWallatNft:{
        name1:'钱包名称',
        name2:'优先级分组',
        name3:'充值文案',
        name4:'提现文案',
        name5:'链/协议/合约',

        text3:'钱包地址选择',
        text4:'新地址',
        text5:'已有地址',
        text6:'钱包地址',
        text1:'充提文案',
        text2:'文案设置',

        relus1:'请填写数字',
        relus2:'请填写大于1的数字',
    },
    withdrawNft:{
        name1:'目标地址',
        name2:'提现类型',
        name3:'链费钱包',
        name4:'提现理由',
        name5:'提现数量',
        name6:'重新审核',
        name7:'审核通过',
        name8:'审核拒绝',
        name9:'提现ID',
        name10:'链/协议',
        name11:'作品名称',
        name12:'链费',
        name13:'提现地址',
        name14:'申请人',
        name15:'审核人',
        name16:'审核理由',
        name17:'审核时间',
        name18:'审核操作',
        name18_1:'待审核',
        name18_2:'审核通过',
        name18_3:'审核拒绝',

        text1:'添加',
        text2:'重新审核',
        
    },
    nfts:{
        text1:'选择链/协议',
        text2:'选择地址',
        text3:'请选择前置项',
        text4:'确认要开启归集功能吗？',
        text5:'只有开启后，才能新增NFT出入金钱包。',
        text6:'开启后，客户的NFT将自动归集到你的NFT出入金热钱包。',
        text7:'归集总开关',
        text8:'关闭',
        text9:'开启',
        text10:'请设置每个APP的归集策略',
        text11:'链/币种/钱包',
        text12:'系列/作品',
        text13:'系列名称',
        text14:'提现时间',
        text15:'审核设置',
        text16:'新增系列',
        text161:'新增作品',
        text17:'详情',
        text18:'大小：5MB 以内',
        text19:'类型：png、jpg、jpeg',

        paragraph1: "请填写审核原因",
        paragraph2: "请选择前置条件",

        name1: '合约地址',
        name2: '描述',
        name3: 'Owner类型',
        name4: '持有者类型',
        name5: '存储者地址',
        name6: '文件后缀',
        name7: '浏览器URL',
        name81: '持有者',
        name8: '商户',
        name9: '客户',
        name10: '作品',
        name11: '作品名',
        name12: '作品描述',
        name13: '拥有者地址',
        name14: '合约名称',
        name15: '系列封面图',
        name16: '作品最大数量',
        name17: '创建者地址',
        name18: '创建时间',
        name19: '数量',
        name20: '系列全称',
        name21: '系列简称',
        name22: '系列封面',

        relus1: '尺寸：',
        relus2: '大小：5MB 以内',
        relus3: '类型：',


    },
    news:{
        name1:'新增成功',
        name2:'查看 Mint 进度',
        name3:'链/原生币',
        name4:'请前往记录查询查看',
        name5:'属性',
        name6:'类型',
        name7:'姓名',
        text1:'简体中文',
        text2:'繁体中文',
        text3:'English',

    }
}

