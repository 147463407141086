import request from '@/utils/axios'

const deptList = (query) => {//部门列表
    return request({
        url: '/admin/dept/tree',
        method: 'get',
        params: query
    })
}
const deptListTree = (query) => {//部门列表
    return request({
        url: '/admin/dept/tree',
        method: 'get',
        params: query
    })
}
const getdeptObj = (id) => {//部门列表
    return request({
        url: '/admin/dept/' + id,
        method: 'get'
    })
}
const updateDeptObj = (obj) => {//部门列表
    return request({
        url: '/admin/dept/',
        method: 'put',
        data: obj
    })
}
const addDeptObj = (obj) => {//部门列表
    return request({
        url: '/admin/dept/',
        method: 'post',
        data: obj
    })
}
const delDeptObj = (id) => {//部门列表
    return request({
        url: '/admin/dept/' + id,
        method: 'delete'
    })
}

export default{
    deptList,deptListTree,getdeptObj,updateDeptObj,
    addDeptObj,delDeptObj
}