import { createI18n } from 'vue-i18n';
import ZH from './zh/index.js';
import EN from './en/index.js';
import ZH_HK from './zh_hk/index.js'
import { languagesObj } from '@/config/language.js'

import enLocale from 'element-plus/dist/locale/en.mjs'
import zhLocale from 'element-plus/dist/locale/zh-cn.mjs'
// import zhtwLocale from 'element-ui/lib/locale/lang/zh-TW'

const isTrue = ()=>{
    if(sessionStorage.getItem('lang')){
        if(languagesObj[sessionStorage.getItem('lang')]){
            return sessionStorage.getItem('lang')
        }else{
            sessionStorage.setItem('lang','en' )
            return 'en' 
        }
    }else{//当前没有语言状态默认显示繁体
        return 'en'
    }
}
const messages = {
  zh: { ...ZH,...zhLocale  },
  en: { ...EN,...enLocale },
  zh_hk: { ...ZH_HK,...zhLocale }
};
const i18n = createI18n({
    legacy: false,//是否启用旧版的 Vue I18n API。默认值为 true。如果你希望使用新版的 Composition API，可以将该选项设置为 false。
    locale: isTrue(),
    messages
});
export default i18n;