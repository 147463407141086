import request from '@/utils/axios'
import moment from "moment";

const getMessManagementList = (params) => {//获得树状权限列表
    if(params.createTime && params.createTime.length > 0){
        params.createTime[0] = moment(params.createTime[0]).format("YYYY-MM-DD HH:ss:mm")  
        params.createTime[1] = moment(params.createTime[1]).format("YYYY-MM-DD HH:ss:mm")  
    }
    return request({
        url: '/message/sysmsghistory/page',
        method: 'get',
        params: params
    })
}

export default{
    getMessManagementList
}