import request from '@/utils/axios'

const addRole = (obj) => {//添加角色
    return request({
        url: '/admin/role',
        method: 'post',
        data: obj,
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const delRole = (id) => {//删除角色
    return request({
        url: '/admin/role/' + id,
        method: 'delete',
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const getRoleList = (query) => {//获得角色列表 
    return request({
        url: '/admin/role/page',
        method: 'get',
        params: query,
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const fetchRoleTree = ( roleId ) => {//???
    return request({
        url: '/admin/menu/tree/' + roleId,
        method: 'get',
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const fetchRoleTreeNew_1 = ( tenantId ) => {//???
    return request({
        url: '/admin/menu/tree/tenant_admin/' + tenantId,
        method: 'get',
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const fetchRoleTreeNew = ( items ) => {//???
    return request({
        url: '/admin/menu/tree/' + items.roleId,
        method: 'get',
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const permissionUpd = ( roleId, menuIds ) => {//???
    return request({
        url: '/admin/role/menu',
        method: 'put',
        data: {
            roleId: roleId,
            menuIds: menuIds
        },
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const updateRole = (obj) => {//修改用户角色
    return request({
        url: '/admin/role',
        method: 'put',
        data: obj,
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const getObjByCode = (code) => {//识别是否已经有了同名角色标识
    return request({
        url: '/admin/role/code/' + code,
        method: 'get',
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const getObjByRoleName = (RoleName) => {//识别是否已经有了同名角色
    return request({
        url: '/admin/role/RoleName/' + RoleName,
        method: 'get'
    })
}

export default{
    addRole,delRole,
    getRoleList,fetchRoleTree,permissionUpd,updateRole,fetchRoleTreeNew,
    getObjByCode,getObjByRoleName,fetchRoleTreeNew_1
}