import request from '@/utils/axios'
// import moment from "moment";

const logList = (params) => {//日志列表
    // if(params.createTime && params.createTime.length > 0){
    //     params.createTime[0] = params.createTime[0] 
    //     params.createTime[1] = params.createTime[1] 
    // }
    return request({
        url: '/admin/log/page',
        method: 'get',
        params: params
    })
}
const logType = () => {//日志列表
    return request({
        url: '/admin/dict/type/log_type',
        method: 'get'
    })
}
const delListlog = (id) => {//删除日志
    return request({
        url: '/admin/log/' + id,
        method: 'delete'
    })
}
const delListlogs = (ids) => {//删除日志
    return request({
        url: '/admin/log/',
        method: 'delete',
        data: ids
    })
}

export default{
    logList,delListlog,delListlogs,
    logType
}