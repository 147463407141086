import axios from 'axios'
import {serialize} from './util'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import { ElMessage, ElMessageBox } from 'element-plus'
import qs from 'qs'
import { questStore } from '@/store/quest'
import website from '@/config/website'

NProgress.configure({
    showSpinner:false
})
axios.defaults.timeout = 30000
axios.defaults.baseURL = website.urls //测试
// axios.defaults.baseURL = sessionStorage.getItem('baseURL') || 'https://waa-serv.com/api/' //正式
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
NProgress.configure({
  showSpinner: false
})

// HTTPrequest拦截
axios.interceptors.request.use(config => {
  NProgress.start() // start progress bar
//   config.url = '/api_'+config.url;//代理bug优化
  const token = questStore().getToken
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token// token
  }
  config.headers['TENANT-ID'] = sessionStorage.getItem(website.key+'-tenantId') || '1'// 租户ID
  config.headers['Language'] = sessionStorage.getItem('lang') || 'en' // 租户ID
  config.headers['USER-ID'] = sessionStorage.getItem('userInfo')?JSON.parse(sessionStorage.getItem('userInfo'))[0].userId:'' // 租户ID

  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})

// HTTPresponse拦截
axios.interceptors.response.use(res => {
  NProgress.done()
  const status = Number(res.status) || 200
  // 后台定义 424 针对令牌过去的特殊响应码
  if (status === 424) {
    ElMessageBox.confirm('令牌状态已过期，请点击重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
        sessionStorage.removeItem('Authorization')
        location.reload()
    })
    return
  }
  if (status !== 200 || res.data.code === 1) {
    return res.data
  }else{
    return res.data
  }

  
}, error => {
  // 处理 503 网络异常
  if (error.response && error.response.status && error.response.status === 503) {
    ElMessage({
      message: error.response.data.msg,
      type: 'error'
    })
  }
  NProgress.done()
  return Promise.reject(new Error(error))
})

export default axios
