export default{
    currencySetNft:{
        name1:'商戶',
        name2:'App名稱',
        name3:'鏈/原生幣',
        name4:'錢包名稱',
        name5:'優先級分組',
        name6:'創建時間',
        name7:'修改時間',
        name8:'最後修改人',
        name9:'狀態',
        name10:'原生幣',

        name11_1:'開啟',
        name11_2:'關閉',
        name29:'Google 驗證碼',

        text1:'歸集',
        text2:'提現',
        text3:'新增',
        text4:'編輯',
        text5:'NFT流水',
        text6:'鏈',
        text7:'協議',
        text8:'操作',
    },
    otherWallatNft:{
        name1:'錢包名稱',
        name2:'優先級分組',
        name3:'充值文案',
        name4:'提現文案',
        name5:'鏈/協議/合約',

        text3:'錢包地址選擇',
        text4:'新地址',
        text5:'已有地址',
        text6:'錢包地址',
        text1:'充提文案',
        text2:'文案設置',

        relus1:'請填寫數字',
        relus2:'請填寫大於1的數字',
    },
    withdrawNft:{
        name1:'目標地址',
        name2:'提現類型',
        name3:'代幣',
        name4:'提現理由',
        name5:'提現數量',
        name6:'重新審核',
        name7:'審核通過',
        name8:'審核拒絕',
        name9:'提現ID',
        name10:'鏈/協議',
        name11:'作品名稱',
        name12:'鏈費',
        name13:'提現地址',
        name14:'申請人',
        name15:'審核人',
        name16:'審核理由',
        name17:'審核時間',
        name18:'審核操作',
        name18_1:'待審核',
        name18_2:'審核通過',
        name18_3:'審核拒絕',

        text1:'添加',
        text2:'重新審核',
        
    },
    nfts:{
        text1:'選擇鏈/協議',
        text2:'選擇地址',
        text3:'請選擇前置項',
        text4:'確認要開啟歸集功能嗎？ ',
        text5:'只有開啟後，才能新增NFT出入金錢包。 ',
        text6:'開啟後，客戶的NFT將自動歸集到你的NFT出入金熱錢包。 ',
        text7:'歸集總開關',
        text8:'關閉',
        text9:'開啟',
        text10:'請設置每個APP的歸集策略',
        text11:'鏈/幣種/錢包',
        text12:'系列/作品',
        text13:'系列名稱',
        text14:'提現時間',
        text15:'審核設置',
        text16:'新增系列',
        text161:'新增作品',
        text17:'詳情',
        text18:'大小：5MB 以內',
        text19:'類型：png、jpg、jpeg',

        paragraph1: "請填寫審核原因",
        paragraph2: "請選擇前置條件",

        name1: '合約地址',
        name2: '描述',
        name3: 'Owner類型',
        name4: '持有者類型',
        name5: '存儲者地址',
        name6: '文件後綴',
        name7: '瀏覽器URL',
        name81: '持有者',
        name8: '商戶',
        name9: '客戶',
        name10: '作品',
        name11: '作品名',
        name12: '作品描述',
        name13: '擁有者地址',
        name14: '合約名稱',
        name15: '系列封面圖',
        name16: '作品最大數量',
        name17: '創建者地址',
        name18: '創建時間',
        name19: '數量',
        name20: '系列全稱',
        name21: '系列簡稱',
        name22: '系列封面',

        relus1: '尺寸：',
        relus2: '大小：5MB 以內',
        relus3: '類型：',

    },
    news:{
        name1:'新增成功',
        name2:'查看 Mint 進度',
        name3:'鏈/原生幣',
        name4:'請前往記錄査詢查看',
        name5:'属性',
        name6:'類型',
        name7:'姓名',
        text1:'簡體中文',
        text2:'繁體中文',
        text3:'English',

    }
}

