import request from '@/utils/axios'

const parmsListAll = (query) => {
    return request({
        url: '/admin/param/page?tenantId=1',
        method: 'get',
        params: query
    })
}
const addParmsListAll = (obj) => {
    return request({
        url: '/admin/param/batch',
        method: 'post',
        data: obj
    })
}
const delParmsObjAll = (id) => {
    return request({
        url: '/admin/param/batch/' + id,
        method: 'delete'
      })
}
const putParmsObjAll = (obj) => {
    return request({
        url: '/admin/param/batch',
        method: 'put',
        data: obj
    })
}

export default{
    parmsListAll,addParmsListAll,
    delParmsObjAll,putParmsObjAll,
}