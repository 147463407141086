import request from '@/utils/axios'
// import moment from "moment";

const getCustomerWithdrawalList = (params) => {//分页查询
    // let url = '/assets/assetstenantwalletconfig/page'
    // if(params.createTime && params.createTime.length > 0){
    //     let sendTimeS = moment(params.createTime[0]).format("YYYY-MM-DD HH:ss:mm")   	
    //     let sendTimeE = moment(params.createTime[1]).format("YYYY-MM-DD HH:ss:mm")   	
    //     url = url + '?createTimes='+ sendTimeS+'&createTimes='+ sendTimeE;
    //     delete params.createTime
    // }
    if(params && params.chain_protocol && params.chain_protocol.length > 0){
        params.chain = params.chain_protocol[0]
        params.protocol = params.chain_protocol[1]
        delete params.chain_protocol
    }
    return request({
        url: '/assets/withdrawapply/page',
        method: 'get',
        params: params
    })
}
const addCustomerWithdrawal = (data,code)=>{//新增加
    let url_ = ''
    if(code){
        url_ = '/assets/withdrawapply/'+code
    }else{
        url_ = '/assets/withdrawapply/ /'
    }
    return request({
        url: url_,
        method: 'post',
        data: data
    })
}
const updateCustomerWithdrawal = (data)=>{//修改
    return request({
        url: '/assets/withdrawapply/audit',
        method: 'put',
        data: data
    })
}
const delCustomerWithdrawal = (id)=>{//删除
    return request({
        url: '/assets/withdrawapply/'+ id,
        method: 'DELETE',
    })
}
const entryTxId = (data)=>{//删除
    return request({
        url: '/assets/withdrawapply/entry_txId',
        method: 'put',
        data: data
    })
}
export default{
    getCustomerWithdrawalList,
    addCustomerWithdrawal,
    updateCustomerWithdrawal,
    delCustomerWithdrawal,
    entryTxId
}