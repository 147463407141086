import request from '@/utils/axios'
import moment from "moment";

const getCurrencyList = (params) => {//分页查询
    if(params && params.chain_protocol && params.chain_protocol.length > 0){
        params.chain = params.chain_protocol[0]
        params.protocol = params.chain_protocol[1]
        delete params.chain_protocol
    }
    if(params && params.createTime && params.createTime.length > 0){
        params.beginTime = moment(params.createTime[0]).format("YYYY-MM-DD HH:ss:mm")  
        params.endTime = moment(params.createTime[1]).format("YYYY-MM-DD HH:ss:mm")  
        delete params.createTime
    }
    return request({
        url: '/chain/chaincoininfo/page',
        method: 'get',
        params: params
    })
}
const addCurrencyObj = (obj) => {//添加币
    return request({
        url: '/chain/chaincoininfo/'+obj.googleCode,
        method: 'post',
        data: obj,
    })
}
const updateCurrencyStatus = (obj) => {//修改状态
    return request({
        url: '/chain/chaincoininfo/updateStatus',
        method: 'post',
        data: obj,
    })
}
const updateCurrencyStatusTo = (obj) => {//修改充值状态
    return request({
        url: '/chain/chaincoininfo/updateDepositStatus',
        method: 'post',
        data: obj,
    })
}
const updateCurrencyStatusGet = (obj) => {//修改提现状态
    return request({
        url: '/chain/chaincoininfo/updateWithdrwaStatus',
        method: 'post',
        data: obj,
    })
}
const updateCurrencyObj = (obj) => {//修改
    return request({
        url: '/chain/chaincoininfo/'+obj.googleCode,
        method: 'put',
        data: obj,
    })
}
export default{
    getCurrencyList,addCurrencyObj,updateCurrencyObj,updateCurrencyStatus,updateCurrencyStatusTo,updateCurrencyStatusGet
}