export default {
    deptFrom:{
        text1: '上級部門',
        text2: '部門名稱',
        text3: '排序',
        text4: '創建時間',
        text5: '操作',
        rules1: '同名部門已存在',
        rules2: '根部門',
    },
    inface:{
        text1: '查看 Secret',
        text2: '重置 Secret',
        text3: '商戶名稱',
        text4: 'App名稱',
        text5: 'Key 和 secret',
        text6: '生成',
        text7: '充值回調地址',
        text8: '提取回調地址',
        text9: 'NFT Deploy 合約回調地址',
        text10: 'NFT Mint回調地址',
        text11: 'NFT 充值回調地址',
        text12: 'NFT 提現回調地址',
        text12_1: '准入 IP',
        text13: 'Goole 驗證碼',
        text14: '狀態',
        text15: 'IP地址',
        text15_1: '打開',
        text15_2: '關閉',
        text16: '創建時間',
        text17: '修改時間',
        text18: '操作人',
        text19: '最後使用時間',
        text19_1: '開啟',
        text19_2: '禁止',
        text20:'白名單限制',
        rules1:'請填寫 IP 地址',
        text21:'（多個 ip 用半角逗號隔開，例如：1.2.3.4,4.5.6.7；最多支持 20 個 ip，此 IP 地址請聯繫 IT 人員索取）',
        text22:'（可配多組，逗號隔開。例如：1.2.3,1.2.3.4）',
    },
    notice:{
        text1: '商戶名稱',
        text2: 'APP名稱',
        text3: '用戶名',
        text4: '低水位預警',
        text5: '短信提醒',
        text6: '郵箱提醒',
        text7: 'Telegram 提醒',
        text8: '操作人',
        text9: '手機號',
        text10: '郵箱',
        text11: '語言設定',

        open_: '關閉',
        close_: '開啟'
    },
    role:{
        text1: '權限',
        text2: '分配權限',
    },
    tenant:{
        text1:'序號',
        text2:'商戶id',
        text3:'商戶名稱',
        text4:'商戶編號',
        text5:'商戶域名',
        text6:'開始時間',
        text7:'結束時間',
        text8:'狀態',

        rules1: '同名商戶標識已存在',
        rules2: '域名已存在',
        rules3: '長度在 3 到 32 個字符',

        name1:'地址',
        name2:'簡介',
    },
    use:{
        name1:'請求介面',
        name2:'請求時間',
        name3:'回應時間',

        text1: '詳細記錄',
        text2: '商戶名稱',
        text3: 'APP名稱',
        text4: '時間',
        text5: '調用狀態',
        text6: '操作IP',
        text7: '操作用戶',
        text8: '調用總次數',
        text9: '開始時間',
        text10: '結束時間',
        text11: '操作人',
    },
    user:{
        text1:'序號',
        text2:'用戶名',
        text3:'密碼',
        text5:'姓名',
        text6:'區號',
        text7:'手機號',
        text8:'所屬部門',
        text9:'角色',
        text10:'狀態',
        text10_1:'有效',
        text10_2:'鎖定',
        text11:'郵箱',
        text12:'創建時間',

        rules1:'用戶名支持小寫英文、數字、中文',
        rules2:'用戶名已經存在',
        rules3:'長度在 3 到 20 個字符',
        rules4:'長度在 6 到 20 個字符',
        rules5:'長度在 11 個字符',
    }
}