import request from '@/utils/axios'
import utilFun from '../util'

const shopList = (query) => {//获得商户的列表
    query = utilFun.bindParm(query)
    return request({
        url: '/admin/tenant/page',
        method: 'get',
        params: query
    })
}
const shopListAdmin = (query) => {//获得商户的列表
    return request({
        url: '/admin/tenant/adminPage',
        method: 'get',
        params: query
    })
}
const getObjShop = (obj) => {//是否有同名的
    return request({
      url: '/admin/tenant',
      method: 'get',
      params: obj
    })
  }
  const addObjShop = (obj) => {//添加商户
    return request({
        url: '/admin/tenant',
        method: 'post',
        data: obj
    })
}
const putObjShop = (obj) => {
    return request({
      url: '/admin/tenant',
      method: 'put',
      data: obj
    })
}
const putObjShopNew = (obj) => {
    return request({
      url: '/admin/menu/edit/tenant_menu',
      method: 'post',
      data: obj
    })
}
const delObjShop = (id) => {
    return request({
        url: '/admin/tenant/' + id,
        method: 'delete'
    })
}
export default{
    shopList,getObjShop,addObjShop,putObjShopNew,delObjShop,shopListAdmin,putObjShop
}