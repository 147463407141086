import request from '@/utils/axios'
import moment from "moment";
import utilFun from '../util'

const getNftcollectioninfoList = (params)=>{
    let url = '/assets/nftcollectioninfo/pageByMer'
    params = utilFun.bindParm(params)
    if(params && params.createTime && params.createTime.length > 0){
        let sendTimeS = moment(params.createTime[0]).format("YYYY-MM-DD HH:ss:mm")   	
        let sendTimeE = moment(params.createTime[1]).format("YYYY-MM-DD HH:ss:mm")   	
        url = url + '?createTimes='+ sendTimeS+'&createTimes='+ sendTimeE;
        delete params.createTime
    }
    return request({
        url: url,
        method: 'get',
        params: params
    })
}
const addNftcollectioninfo = (data,code)=>{
    let url = '/assets/nftcollectioninfo/' + code
    return request({
        url: url,
        method: 'post',
        data: data
    })
}
const getImgUrl = (data)=>{
    return request({
        url: '/admin/sys-file/uploadFileToOSS',
        method: 'post',
        data: data
    })
}
const addNftWrite = (data)=>{//新增文案
    return request({
        url: '/assets/nftdescription',
        method: 'post',
        data: data
    })
}
const updateNftWrite = (data)=>{//修改文案
    return request({
        url: '/assets/nftdescription',
        method: 'put',
        data: data
    })
}
const getNftWriteList = (data)=>{//修改文案
    return request({
        url: '/assets/nftdescription/page',
        method: 'get',
        params: data
    })
}
const putnftcollectioninfo = (data)=>{//修改文案
    return request({
        url: '/assets/nftcollectioninfo',
        method: 'put',
        data: data
    })
}
export default{
    getNftcollectioninfoList,addNftcollectioninfo,getImgUrl,
    addNftWrite,updateNftWrite,getNftWriteList,putnftcollectioninfo
}