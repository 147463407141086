export default {
    deptFrom:{
        text1: 'Higher Office',
        text2: 'Department Name',
        text3: 'Sort',
        text4: 'Created Time',
        text5: 'Operation',
        rules1: 'Department already exists',
        rules2: 'Root gate',
    },
    inface:{
        text1: 'Secret',
        text2: 'Confirm rest secret',
        text3: 'Merchant name',
        text4: 'App Name',
        text5: 'Key and secret',
        text6: 'Generate',
        text7: 'Deposit Callback Address',
        text8: 'Withdrawl Callback Address',
        text9: 'NFT Deploy Contract Callback Address',
        text10: 'NFT Mint Callback Address',
        text11: 'NFT Deposit Callback Address',
        text12: 'NFT Withdrawal Callback Address',
        text12_1: 'Access IP',
        text13: 'Goole Code',
        text14: 'Status',
        text15: 'IP Address',
        text15_1: 'On',
        text15_2: 'Off',
        text16: 'Created Time',
        text17: 'Edited Time',
        text18: 'Operator',
        text19: 'Last Use Time',
        text19_1: 'On',
        text19_2: 'Off',
        text20:'Whitelist Restriction',
        rules1:'IP Address',
        text21:'（Separate multiple ips with commas, for example: 1.2.3.4,4.5.6.7;Up to 20 ips are supported, please contact IT staff for this IP address）',
        text22:'（Multiple groups can be allocated, separated by commas. For example: 1.2.3, 1.2.3.4）'
    },
    notice:{
        text1: 'Merchant name',
        text2: 'App Name',
        text3: 'User name',
        text4: 'Low Quantity NOTIF',
        text5: 'SMS Reminder',
        text6: 'Mailbox Reminder',
        text7: 'Telegram Reminder',
        text8: 'Operator',
        text9: 'Phone',
        text10: 'Mailbox',
        text11: 'Language settings',
        open_: 'Off',
        close_: 'On'
    },
    role:{
        text1: 'Permission',
        text2: 'Assign Permissions',
    },
    tenant:{
        text1: 'SN',
        text2: 'Merchant id',
        text3: 'Merchant name',
        text4: 'Merchant No.',
        text5: 'Merchant Domain',
        text6: 'Start Time',
        text7: 'End Time',
        text8: 'Status',
        rules1: 'Merchant ID already exists',
        rules2: 'Domain name already exists',
        rules3: '3 to 32 characters in length',

        name1:'Address',
        name2:'Introduction',
    },
    use:{
        name1:'Request Interface',
        name2:'Request time',
        name3:'Response time',

        text1: 'Details',
        text2: 'Merchant name',
        text3: 'App Name',
        text4: 'Time',
        text5: 'Callback Status',
        text6: 'Operation IP',
        text7: 'Operation user',
        text8: 'Callback Sum',
        text9: 'Start Time',
        txt10: 'End Time',
        text11: 'Operator',
    },
    user:{
        text1: 'SN',
        text2: 'User name',
        text3: 'Password',
        text5: 'Name',
        text6: 'Area Code',
        text7: 'Phone',
        text8: 'Department',
        text9: 'Role',
        text10: 'Status',
        text10_1: 'On',
        text10_2: 'Off',
        text11: 'Mailbox',
        text12: 'Created Time',
        rules1: 'User name supports lowercase English, numbers and Chinese',
        rules2: 'The user name already exists',
        rules3: '3 to 20 characters in length',
        rules4: '6 to 20 characters in length',
        rules5: 'The character length is less than or equal to 11',
    }
}
