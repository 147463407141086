import request from '@/utils/axios'

const getUseList = (query) => {
    return request({
        url: '/admin/apirequestinfo/pageByMer',
        method: 'get',
        params: query
    })
}
const getUseListDetail = (query) => {
    return request({
        url: '/admin/apirequestinfo/page',
        method: 'get',
        params: query
    })
}
export default{
    getUseList,getUseListDetail
}