export default {
    agreement:{
        text1:'序號',
        text2:'鏈',
        text3:'協議',
        text4:'適用場景',
        text4_1:'無',
        text5:'創建時間',
        text6:'操作人',
        text7:'狀態',
        text7_1:'關閉',
        text7_2:'可用',
    },
    chain:{
        text1:'序號',
        text2:'鏈',
        text3:'原生幣',
        text4:'快速上賬區塊/次',
        text5:'安全上賬區塊/次',
        text6:'Gas Tracker API',
        text7:'虛擬機類型',
        text8:'Token',
        text8_1:'無',
        text8_2:'有',
        text9:'創建時間',
        text10:'修改時間',
        text11:'最後修改人',
        text12:'狀態',
        text12_1:'關閉',
        text12_2:'可用',
    },
    contra:{
        text1:'序號',
        text2:'適用場景',
        text3:'無',
        text4:'合約名稱',
        text5:'鏈/協議',
        text6:'合約類型',
        text7:'標準',
        text8:'合約內容',
        text9:'創建時間',
        text101:'修改時間',
        text10:'最後修改人',
        text11:'狀態',
        text12:'關閉',
        text13:'可用',
        text14:'合約類名稱',

        name1: 'Mint 許可權',
            name1_1: 'Owner',
            name1_2: '任何人',
        name2: '適用場景',
            name2_1: '供應量',
            name2_2: '不限量',
    },
    curr:{
        text1:'幣種名稱',
        text2:'幣種類型',
        text3:'幣',
        text4:'代幣',
        text5:'鏈/協議',
        text6:'標籤',
        text7:'有',
        text8:'無',
        text9:'合約地址',
        text10:'小數位',
        text11:'最小歸集數量',
        text12:'瀏覽器 URL',
        text13:'創建時間',
        text14:'修改時間',
        text15:'操作人',
        text16:'狀態',
        text17:'充值',
        text18:'提現',
        text19:'google驗證碼',
        text20:'鏈',


        relus1:'請輸入大於0的數',
        relus2:'請輸入正整數',

    }
}