import request from '@/utils/axios'

const fetchMenuTree = (params) => {//获得树状权限列表
    return request({
        url: '/admin/menu/tree',
        method: 'get',
        params: params
    })
}
const fetchMenuTreeId = (id) => {//获得树状权限列表
    return request({
        url: '/admin/menu/tree/' + id,
        method: 'get'
    })
}
const fetchMenuTreeButton = () => {//获得按钮权限
    return request({
        url: '/admin/menu/tree?menuName=',
        method: 'get'
    })
}
const getMenuList = (id) => {//获取某个菜单id下的数据，服务于修改菜单界面
    return request({
        url: '/admin/menu/' + id,
        method: 'get',
    })
}
const updateMenu = (obj) => {//修改菜单
    return request({
        url: '/admin/menu',
        method: 'put',
        data: obj,
    })
}
const getMenuListDetail = (params)=>{///
    return request({
        url: 'admin/sysmenuname/page',
        method: 'get',
        params: params
    })
}
const addMenu = (obj) => {//添加菜单
    return request({
        url: '/admin/menu',
        method: 'post',
        data: obj,
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}
const delMenu = (id) => {//删除菜单
    return request({
        url: '/admin/menu/' + id,
        method: 'delete',
        headers: {
            isToken: true,
            'TENANT-ID': 1
        }
    })
}

export default{
    fetchMenuTree,getMenuList,updateMenu,addMenu,delMenu,
    fetchMenuTreeButton,fetchMenuTreeId,getMenuListDetail
}