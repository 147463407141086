import chainApi from './chain'
import agreementApi from './agreement'
import contractApi from './contract'
import currencyApi from './currency'

export default {
    ...chainApi,
    ...agreementApi,
    ...contractApi,
    ...currencyApi
}