import request from '@/utils/axios'
import utilFun from '../util'

const getUserappinfoList = (query) => {//app接入
    query = utilFun.bindParm(query)
    return request({
        url: '/admin/userappinfo/page',
        method: 'get',
        params: query
    })
}
const createAppKeyAndAppSecret = () => {//创建APP key secret
    return request({
        url: 'admin/userappinfo/createAppKeyAndAppSecret',
        method: 'get'
    })
}
const addAppinfoList = (obj) => {//添加APP
    return request({
        url: '/admin/userappinfo/'+obj.newGoogleKey,
        method: 'post',
        data: obj
        
    })
}
const updateAppinfoList = (obj) => {//修改APP
    if(obj.newGoogleKey){
        return request({
            url: 'admin/userappinfo/'+obj.newGoogleKey,
            method: 'put',
            data: obj
        })
    }else{
        return request({
            url: 'admin/userappinfo/no_google_code',
            method: 'post',
            data: obj
        })

    }
}
const updateApp_ = (obj) => {//修改APP的是否归集的接口
    return request({
        url: 'admin/userappinfo',
        method: 'put',
        data: obj,
    })
}
const updateApp_1 = (obj) => {//修改APP的是否归集的接口
    return request({
        url: 'admin/userappinfo/nft/collection',
        method: 'put',
        data: obj,
    })
}
const getIpinfoList = (query) => {//白名单列表
    if(query.createTime){
        query.createTimeStart = query.createTime[0]
        query.createTimeEnd = query.createTime[1]
        delete query.createTime
    }
    return request({
        url: '/admin/useripwhitelist/page',
        method: 'get',
        params: query
    })
}
const addIpinfoList = (obj) => {//添加白名单
    return request({
        url: 'admin/useripwhitelist',
        method: 'post',
        data: obj
    })
}
const delIpinfoList = (id) => {//删除白名单
    return request({
        url: '/admin/useripwhitelist/' + id,
        method: 'delete'
    })
}
const updateIpinfoList = (obj) => {//修改白名单
    return request({
        url: '/admin/useripwhitelist',
        method: 'put',
        data: obj
    })
}
export default{
    getUserappinfoList,createAppKeyAndAppSecret,updateAppinfoList,
    addAppinfoList,getIpinfoList,addIpinfoList,delIpinfoList,
    updateIpinfoList,updateApp_,updateApp_1
}