export default{
    currencySet:{
        name1:'商户',
        name2:'App名称',
        name3:'链/协议/币种',
        name4:'合约地址',
        name5:'直接归集水位线',
        name6:'热钱包装载上限',
        name7:'热钱包预警阈值',
        name8:'链费钱包预警阈值',
        name9:'最终冷钱包装载上限',
        name10:'最小归集数量',
        name11:'提现限制',
        name11_1:'开启',
        name11_2:'禁止',
        _name11:'归集状态',
        _name11_1:'归集',
        _name11_2:'不归集',
        name12:'最小提现数量',
        name13:'单笔提现上限',
        name14:'单日提现上限',
        name15:'日间时间段',
        name16:'开始时间',
        name17:'结束时间',
        name18:'日间单笔免审额度',
        name19:'日间单客户提现触发审核总次数',
        name20:'日间单客户提现触发审核总额度',
        name21:'日间所有客户提现触发审核总额度',
        name22:'夜间时间段',
        name23:'夜间开始时段',
        name24:'夜间结束时段',
        name25:'夜间单笔免审额度',
        name26:'夜间单客户提现触发审核总次数',
        name27:'夜间单客户提现触发审核总额度',
        name28:'夜间所有客户提现触发审核总额度',
        name29:'Google 验证码',
        name30:'选择链/协议/币',
        name31:'选择',

        rule1:'请输入大于0的数',
        text1:'币种名称',
        text2:'币种类型',
        text3:'币',
        text4:'代币',
        text5:'链/协议',
        text6:'充值文案',
        text7:'提币文案',
        text8:'简体中文',
        text9:'繁体中文',
        text10:'链',
        text10_1:'币',
        text11:'协议',
        text12:'状态',
        text13:'创建时间',
        text14:'最后使用时间',
        text15:'操作人',
        text16:'文案设置'//currencySet
    },
    otherWallat:{
        name1:'钱包名称',
        name2:'优先级分组',
        name3:'余额',
        name4:'最后修改时间',
        name5:'数量',
        name6:'预警阈值',

        text1:'开始时间',
        text2:'结束时间',
        text3:'钱包地址选择',
        text4:'新地址',
        text5:'已有地址',
        text6:'钱包地址',
    },
    withdraw:{
        name1:'目标地址',
        name2:'提现理由',
        name3:'提现数量',
        name4:'提现ID',
        name5:'钱包类型',
        name6:'链费',
        name7:'提现地址',
        name8:'申请人',
        name9:'审核人',
        name10:'审核理由',
        name11:'提现时间',
        name12:'审核时间',
        name12_1:'待审核',
        name12_2:'审核通过',
        name12_3:'审核拒绝',
        name13:'审核操作',

        text1:'重新审核',
        text2:'审核通过',
        text3:'审核拒绝',
        text4:'出入金热钱包',
        text5:'链费钱包',
        text6:'链/币种/钱包',

        paragraph1:'确认要重新申请吗？重新申请会自动生成一条新的申请记录',
        paragraph2:'确认要拒绝审核吗？请填写拒绝原因（必填）',
        paragraph3:'确认要通过审核吗？请填写通过原因（选填）',
        paragraph4:'重新申请成功，你的新申请记录ID为：',
        paragraph5: "请填写审核原因",
        paragraph6: "请选择前置条件",
    },
    withdraw1:{
        name1:'审核设置',
        paragraph1:'关闭审核后，客户提现将无需审核',
        paragraph2:'开启审核后，客户提现将打开审核',
    }
}