export default {
    getMenu:{
        loadingMenu:'获取菜单中。。。'
    },
    login: {
        tit:'錢包管理後台',
        language:'語言',
        input1:'賬號',
        input2:'密碼',
        input3:'Google 驗證碼',
        button: '登錄',
        text1:'請輸入賬號',
        text2:'請輸入密碼',
        text3:'請輸入 Google 驗證碼',
        tit2: '讓加密支付變得簡單',
        tit3: '安全。合規。易於使用。',
    },
    homePage:{
        'wel1':'歡迎使用企業錢包',
        'wel2':'管理後臺',
    },
    button: {
        search: '搜索',
        add: '添加',
        del: '刪除',
        update: '編輯',
        enter: '確定',
        close: '取消',
        imports: '導出',
        authority: '權限',
        newer: '更新',
        cache:'緩存',
        dictionary:'字典項',
        refresh:'刷新',
        open_:'開啟',
        close_:"關閉",
        moreDel: '批量刪除',
        tijiao:'提交',
        closeQ: '關閉',
        look:'查看',
        writes:'充提文案',
        more:'更多',
        compla:'完成',
        remove:'重置',
        imports1:'導入',
        checks:'選擇',
        operation:'操作',
        closeq1:'關閉其他',
        upload:'上傳',
        getLine: '链接'
    },
    inputTit:{
        shuru:'請輸入',
        xuanze:'請選擇',
        shangchuan:'請上傳',
        isNull:'不能為空',
    },
    alert:{
        selEnter: '是否確認刪除?',
        succDel: '刪除成功',
        succUpdate: '修改成功',
        succAdd: '添加成功',
        succSync: '同步成功',
        succSync_: '重置成功',
        succBind: '操作成功',

        delText:'删除失敗',
        succOpen:'启用成功',
        succOff:'禁用成功'
    },
    tyle_:{
        warn: '警告',
        tits: '提示',
        sycc: '成功'
    },
    downName:{
        name_1: '消息模板',
        name_2: '日誌模板'
    },
    topText:{
        text1: '錢包管理',
        text2: '首頁',
        text3: '個人信息',
        text4: '退出',
    },
    searchText:{
        text1: '請填寫',
        text2: '請選擇',
        text3: '到',
        text4: '開始時間',
        text5: '結束時間',
    },
    importss:{
        text1:'消息範本導入',
        text2:'將檔案拖到此處，或',
        text3:'點擊上傳',
        text4:'僅允許導入xls、xlsx格式檔案。',
        text5:'下載範本',
    },
    switchGoogle:{
        name1:'更換密碼',
        name2:'新密碼',
        name3:'舊密碼',
        name4:'Google新金鑰',
        name5:'Google驗證碼',
        name6:'溫馨提示',
        name7:'管理員',
        name8:'商戶名',
        text1:'生成',
        text2:'請務必截圖或者拍照保存這些資訊',
        text3:'複製',
        text4:'複製成功',
        text5:'請點擊',
        placeholder: '請使用Google新金鑰生成的驗證碼'
    },
    pages:{
        homeName: '首頁'
    }
};