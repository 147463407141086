import request from '@/utils/axios'

const getSendNftList = (params)=>{
    return request({
        url: '/assets/withdrawapply/pageByBatchWithdrawNFT',
        method: 'get',
        params: params
    })
}
const addSendNftList = (data,code)=>{
    let url = '/assets/withdrawapply/saveBatch/' + code
    return request({
        url: url,
        method: 'post',
        data: data
    })
}
const exportByBatchTxid = (params)=>{
    return request({
        url: '/assets/withdrawapply/exportByBatchTxid',
        method: 'get',
        params: params
    })
}
export default{
    getSendNftList,addSendNftList,exportByBatchTxid
}