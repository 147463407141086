import request from '@/utils/axios'
import moment from "moment";

const getAgreementList = (params) => {//message/syssmstemplate
    if(params && params.createTime && params.createTime.length > 0){
        params.beginTime = moment(params.createTime[0]).format("YYYY-MM-DD HH:ss:mm")  
        params.endTime = moment(params.createTime[1]).format("YYYY-MM-DD HH:ss:mm")  
        delete params.createTime
    }
    return request({
        url: '/chain/chainprotocolinfo/page',
        method: 'get',
        params: params
    })
}
const addAgreementObj = (obj) => {//添加角色
    return request({
        url: '/chain/chainprotocolinfo',
        method: 'post',
        data: obj,
    })
}
const updateAgreementStatus = (obj) => {//修改状态
    return request({
        url: '/chain/chainprotocolinfo/updateStatus',
        method: 'post',
        data: obj,
    })
}
const updateAgreementObj = (obj) => {//修改
    return request({
        url: '/chain/chainprotocolinfo',
        method: 'put',
        data: obj,
    })
}

export default{
    getAgreementList,addAgreementObj,updateAgreementStatus,updateAgreementObj
}