import request from '@/utils/axios'
import moment from "moment";
import utilFun from '../util'

const getCoinList = (params) => {//message/syssmstemplate
    params = utilFun.bindParm(params)
    if(params && params.createTime && params.createTime.length > 0){
        params.beginTime = moment(params.createTime[0]).format("YYYY-MM-DD HH:ss:mm")  
        params.endTime = moment(params.createTime[1]).format("YYYY-MM-DD HH:ss:mm")  
        delete params.createTime
    }
    return request({
        url: '/chain/chaininfo/page',
        method: 'get',
        params: params
    })
}
const addCoinObj = (obj) => {//添加角色
    return request({
        url: '/chain/chaininfo',
        method: 'post',
        data: obj,
    })
}
const updateCoinStatus = (obj) => {//修改状态
    return request({
        url: '/chain/chaininfo/updateStatus',
        method: 'post',
        data: obj,
    })
}
const updateCoinObj = (obj) => {//修改
    return request({
        url: '/chain/chaininfo',
        method: 'put',
        data: obj,
    })
}

export default{
    getCoinList,addCoinObj,updateCoinStatus,updateCoinObj
}