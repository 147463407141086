
export const languagesObj = {
    "zh_hk": "繁體中文",
    "en": 'English',
    "zh": "简体中文",
}
export const languagesArray = ['繁體中文','English','简体中文',]
export const languagesOfObject = [{
    label:'繁體中文',
    value:'zh_hk'
},{
    label:'English',
    value:'en'
},{
    label:'简体中文',
    value:'zh'
}]