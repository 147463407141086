import request from '@/utils/axios'

export function fetchListRoute(query) {
    return request({
      url: '/admin/route',
      method: 'get',
      params: query
    })
  }
  
  export function putObjRoute(obj) {
    return request({
      url: '/admin/route',
      method: 'put',
      data: obj
    })
  }
  
  export function refreshObjRoute() {
    return request({
      url: '/admin/actuator/gateway/refresh',
      method: 'post'
    })
  }

export default{
    fetchListRoute,putObjRoute,refreshObjRoute
}