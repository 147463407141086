import request from '@/utils/axios'

const downBlobFileApi = (url, query) => {//下载文档
    return request({
        url: url,
        method: "get",
        responseType: "blob",
        headers: {
            isToken: true
        },
        params: query
      })
}
const changeLanguage = () => {//切换语言
    return request({
        url: '/admin/menu/clearCache',
        method: "get"
      })
}
const bindParm = (query)=>{//当没有传递分页时，默认获得10000条
    if(query === undefined ){
        query = {
            current: 1,
            size: 10000
        }
    }else if(!query.current)(
        query.current = 1,
        query.size = 10000
    )
    return query
}
const parmsType = (type) => {
    return request({
        url: '/admin/dict/type/' + type,
        method: 'get'
    })
}
export default{
    downBlobFileApi,changeLanguage,bindParm,parmsType
}