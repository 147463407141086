export default{
    currencySet:{
        name1: 'Merchant',
        name2: 'App Name',
        name3: 'Chain/Protocol/Currency',
        name4: 'Contract Address',
        name5: 'Direct Collection Quantity',
        name6: 'Max Hot Wallet Quantity',
        name7: 'Hot Wallet Low Quantity Reminder',
        name8: 'Fee Wallet Low Quantity Reminder',
        name9: 'Max Cold Wallet Quantity',
        name10: 'Min Collection Quantity',
        name11: 'Withdrawal Limit',
        name11_1: 'On',
        name11_2: 'Off',
        _name11:'Collection state',
        _name11_1:'Collection',
        _name11_2:'Not collected',
        name12: 'Min Withdrawal Quantity',
        name13: 'Single Withdrawal Limit',
        name14: 'Daily Withdrawal Limit',
        name15: 'Day Time',
        name16: 'Start',
        name17: 'End',
        name18: 'Day-time single-exemption Amount',
        name19: 'Day-time single user withdrawal review numbers',
        name20: 'Day-time single user withdrawal review amount',
        name21: 'Day-time all user withdrawal review amount',
        name22: 'Night Time',
        name23: 'Start',
        name24: 'End',
        name25: 'Night-time single-exemption Amount',
        name26: 'Night-time single user withdrawal review numbers',
        name27: 'Night-time single user withdrawal review amount',
        name28: 'Night-time all user withdrawal review amount',
        name29: 'Google Code',
        name30: 'Select Chain/Protocol/Currency',
        name31: 'Select',
        Rule1: 'Number than 0',
        text1: 'Currency',
        text2: 'Currency type',
        text3: 'Currency',
        text4: 'Token',
        text5: 'Chain/Protocol',
        text6: 'Deposit Copy',
        text7: 'Withdrawal Copy',
        text8: 'Simplified Chinese',
        text9: 'Traditional Chinese',
        text10: 'Chain',
        text10_1:'Currency',
        text11: 'Protocol',
        text12: 'Status',
        text13: 'Created Time',
        text14: 'Last Edited Time',
        text15: 'Operator',
        text16: 'Copy Settings'
    },
    otherWallat:{
        name1: 'Wallet Name',
        name2: 'Priority',
        name3: 'Amount',
        name4: 'Last Edited Time',
        name5: 'Quantity',
        name6: 'Low Quantity Reminder',

        text1: 'Start Time',
        text2: 'End Time',
        text3: 'Wallet Address',
        text4: 'New Address',
        text5: 'Old Address',
        text6: 'Wallet Address',
    },
    withdraw:{
        name1: 'To Address',
        name2: 'Withdrawal Reason',
        name3: 'Withdrawal Quantity',
        name4: 'Withdrawal ID',
        name5: 'Wallet Type',
        name6: 'Fee',
        name7: 'From Address',
        name8: 'Applicant',
        name9: 'Reviewer',
        name10: 'Review Reason',
        name11: 'Withdrawal Time',
        name12: 'Review time',
        name12_1: 'Waitting',
        name12_2: 'Pass',
        name12_3: 'Reject',
        name13: 'Review Operation',
        text1: 'Rereview',
        text2: 'Pass',
        text3: 'Reject',
        text4: 'Withdrawal & Deposit Wallet',
        text5: 'Fee Wallet',
        text6: 'Chain/Currency/Wallet',
        paragraph1: 'Are you sure you want to reapply? Re-applying will automatically generate a new application record',
        paragraph2: 'Are you sure you want to reject the review? Please fill in the reason for rejection (required)',
        paragraph3: 'Are you sure you want to pass the review? Please fill in the reason for passing (optional)',
        paragraph4: 'Re-apply successfully, your new application record ID is:',
        paragraph5: "Please fill in the audit reason",
        paragraph6: "Please select a precondition",
    },
    withdraw1:{
        name1: 'Review Settings',
        paragraph1: 'After the audit is turned off, the user withdrawal will not need to be audited',
        paragraph2: 'After the review is turned on, the user withdrawal will open the review',
    }
}