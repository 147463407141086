import base from './base.js'
import admin from './admin'
import platform from './platform'
import coin from './coin'
import cryptocurrency from './cryptocurrency'
import nft from './nft'

export default{
    ...base,
    ...admin,
    ...platform,
    ...coin,
    ...cryptocurrency,
    ...nft,
}