const subRoutes = [
    {
        path: 'main' ,
        component: () => import('@/views/navigations/index.vue'),
        redirect:'/main/homePage',
        meta:{id:'-2'},//当前设置的id便于匹配路由
        children: [
            {
                path: 'homePage' ,
                component: () => import('@/views/home.vue'),
                meta:{id:'-1',isTab:true,name:'首页'}
            }
        ]
    },
    {
        path: 'login',//登录
        component: () => import('@/views/logins/index.vue'),
        meta:{isTab:false}
    },
]
const routes = [{
    path: '/en',
    children: subRoutes,
    name:'en',
    redirect:'/en/main',
},
 {
    path: '/zh',
    name:'zh',
    children: subRoutes,
    redirect:'/zh/main',
}, 
{
    path: '/zh_hk',
    name:'zh_hk',
    children: subRoutes,
    redirect:'/zh_hk/main',
},
{
    path: '/:catchAll(.*)',
    hidden: true,
    name:'404',
    component: () => import('@/views/error/404.vue'),//这个是我自己的路径
    meta:{isTab:false}
}
];
export default routes;