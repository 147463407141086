import * as CryptoJS from "crypto-js";
import website from '@/config/website'
import Api_ from '@/api/index'
import { ElMessage } from 'element-plus'
import moment from "moment";

//随机数
export const randomCoding = (index) => {
    var arr = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
 	var idvalue ='';
 	for(var i=0;i < index;i++){
    	idvalue+=arr[Math.floor(Math.random()*36)];
 	}
	return idvalue;
}
// 对象序列化
export const serialize = data => {
    const list = [];
    Object.keys(data).forEach(ele => {
      list.push(`${ele}=${data[ele]}`);
    });
    return list.join("&");
};
//加密登录密码
export const encryptions = pass => { 
    let keys = CryptoJS.enc.Latin1.parse(website.passwordValue);
    let encrypted = CryptoJS.AES.encrypt(pass, keys, {
        iv: keys,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.NoPadding
    });
    return encrypted.toString();
};
//解密登录密码
export const encryptionsOpen = value => { 
    let iv = CryptoJS.enc.Latin1.parse(website.passwordValue);
    const decrypt = CryptoJS.AES.decrypt(value, iv, { iv: iv, mode: CryptoJS.mode.CFB, padding: CryptoJS.pad.NoPadding });
    return CryptoJS.enc.Latin1.stringify(decrypt).toString()
};
//下载表格处理
export const downBlobFile = (url,fileName,searchForm) =>{
    Api_.downBlobFileApi(url, searchForm).then(response => { // 处理返回的文件流
        const blob = response;
        if (blob && blob.size === 0) {
            ElMessage.error("内容为空，无法下载")
            return;
        }
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =  fileName;
        document.body.appendChild(link);
        link.click();
        window.setTimeout(function() {
            window.URL.revokeObjectURL(blob);
            document.body.removeChild(link);
        }, 0);
    });
}
//下载表格模板
export const downBlobFileTemplate = (url) =>{
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function() {
        document.body.removeChild(link);
    }, 0);
}
/**
     * 解析出所有的太监节点id
     * @param json 待解析的json串
     * @param idArr 原始节点数组
     * @param temp 临时存放节点id的数组
     * @return 太监节点id数组
     */
export const resolveAllEunuchNodeId = (json, idArr, temp) => {
    for (let i = 0; i < json.length; i++) {
      const item = json[i]
      // 存在子节点，递归遍历;不存在子节点，将json的id添加到临时数组中
      if (item.children && item.children.length !== 0) {
        temp.push(idArr.filter(id => id === item.id))
        resolveAllEunuchNodeId(item.children, idArr, temp)
      } else {
        temp.push(idArr.filter(id => id === item.id))
      }
    }
    return temp
}
export const resolveAllEunuchNodeIdNew = (json, idArr, temp) => {
    for (let i = 0; i < json.length; i++) {
        const item = json[i]
        let bool_ = idArr.some((idArr_item)=>{
            return idArr_item.copyMenuId === item.id
        })
        if (item.children && item.children.length !== 0) {
            if(bool_){
                temp.push([item.id])
            }
            resolveAllEunuchNodeIdNew(item.children, idArr, temp)
        } else {
            if(bool_){
                temp.push([item.id])
            }
        }
    }
    return temp
}
export const turnTimeFun = (str)=>{//讲后台的时间转成0时区的时间
    if(str){
        let _q = str.replace(/\s/g,"T") + 'Z'
        return moment(_q).format("YYYY-MM-DD HH:mm:ss")  
    }
}
export const turnTimeFun1 = (str)=>{//讲后台的时间转成0时区的时间
    if(str){
        let _q = new Date(str)
        return moment(_q).format("YYYY-MM-DD HH:mm:ss")  
    }
}
export const dicFormatter = (e)=>{
    let obj_1 = []
    for(let item of e){
        if(item){
            let obj_ = {
                label: item.chain,
                value: item.chain,
            }
            if(item.chainProtocolInfos.length > 0){
                let obj_c = []
                for(let items of item.chainProtocolInfos){
                    obj_c.push({
                        label: items.protocol,
                        value: items.protocol
                    })
                }
                obj_.children = obj_c
                obj_1.push(obj_)
            }
        }
    }
    return obj_1
}