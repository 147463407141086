export default {
    agreement:{
        text1:'序号',
        text2:'链',
        text3:'协议',
        text4:'适用场景',
        text4_1:'无',
        text5:'创建时间',
        text6:'操作人',
        text7:'状态',
        text7_1:'关闭',
        text7_2:'可用',
    },
    chain:{
        text1:'序号',
        text2:'链',
        text3:'原生币',
        text4:'快速上账区块/次',
        text5:'安全上账区块/次',
        text6:'Gas Tracker API',
        text7:'虚拟机类型',
        text8:'Token',
        text8_1:'无',
        text8_2:'有',
        text9:'创建时间',
        text10:'修改时间',
        text11:'最后修改人',
        text12:'状态',
        text12_1:'关闭',
        text12_2:'可用',
    },
    contra:{
        text1:'序号',
        text2:'适用场景',
        text3:'无',
        text4:'合约名称',
        text5:'链/协议',
        text6:'合约类型',
        text7:'标准',
        text8:'合约内容',
        text9:'创建时间',
        text101:'修改时间',
        text10:'最后修改人',
        text11:'状态',
        text12:'关闭',
        text13:'可用',
        text14:'合约类名称',

        name1: 'Mint 权限',
            name1_1: 'Owner',
            name1_2: '任何人',
        name2: '适用场景',
            name2_1: '供应量',
            name2_2: '不限量',
    },
    curr:{
        text1:'币种名称',
        text2:'币种类型',
        text3:'原生币',
        text4:'代币',
        text5:'链/协议',
        text6:'标签',
        text7:'有',
        text8:'无',
        text9:'合约地址',
        text10:'小数位',
        text11:'最小归集数量',
        text12:'浏览器 URL',
        text13:'创建时间',
        text14:'修改时间',
        text15:'操作人',
        text16:'状态',
        text17:'充值',
        text18:'提现',
        text19:'google验证码',
        text20:'链',


        relus1:'请输入大于0的数',
        relus2:'请输入正整数',

    }
}