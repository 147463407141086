export default {
    getMenu:{
        loadingMenu:'Get menu...'
    },
    login: {
        tit:'Wallet Management',
        language:'Languages',
        input1:'Account',
        input2:'Password',
        input3:'Google Code',
        button: 'Sign In',
        text1:'Please Input Account',
        text2:'Please Input Password',
        text3:'Please Input Google Code',
        tit2: 'Business payments made easy',
        tit3: 'Security. Legal. Easy to use.',
    },
    homePage:{
        'wel1':'Welcome to the Enterprise Wallet',
        'wel2':'Management Backend',
    },
    button: {
        search: 'Search',
        add: 'Add',
        del: 'Delete',
        update: 'Edit',
        enter: 'Confirm',
        close: 'Cancel',
        imports: 'Export',
        authority: 'authority',
        newer: 'Update',
        cache:'Refresh',
        dictionary:'Dictionary',
        refresh:'Refresh',
        open_:'On',
        close_:"Off",
        moreDel: 'Batch Deletion',
        tijiao:'Confirm',
        closeQ: 'Cancel',
        look:'View',
        remove:'Reset',
        imports1:'Import',
        writes: 'Charge Copy',
        more: 'More',
        compla: 'Finish',
        checks:'Select',
        operation:'Operation',
        closeq1:'Close other',
        upload:'Upload',
        getLine: 'Line'
    },
    inputTit:{
        shuru:'Input',
        xuanze:'Select',
        shangchuan:'Upload',
        isNull:'Cannot be null',
    },
    alert:{
        selEnter: 'Confirm Delete?',
        succDel: 'Deleted Successful',
        succUpdate: 'Edited Successful',
        succAdd: 'Add Successful',
        succSync: 'Sync Successful',
        succSync_: 'Reset Successful',
        succBind: 'Operation Successful',
        delText:'Deletion failure',
        succOpen:'Enabled Successfully',
        succOff:'Disabled Successfully'
    },
    tyle_:{
        warn: 'Warning',
        tits: 'Prompt',
        sycc: 'Success'
    },
    downName:{
        name_1: 'Messages Template',
        name_2: 'Log Template'
    },
    topText:{
        text1: 'Wallet Management',
        text2: 'Home',
        text3: 'Personal Information',
        text4: 'Sign Out',
    },
    searchText:{
        text1: 'Input',
        text2: 'Select',
        text3: 'To',
        text4: 'Start Time',
        text5: 'End Time',
    },
    importss:{
        text1: 'Message template import',
        text2: 'Drag the file here, or',
        text3: 'Click to upload',
        text4: 'Support .xls and .xlsx files.',
        text5: 'Download template',
    },
    switchGoogle:{
        name1: 'Change password',
        name2: 'New password',
        name3: 'Old password',
        name4: 'Google New Key',
        name5: 'Google verification code',
        name6: 'Warm prompt',
        name7: 'Administrators',
        name8: 'Merchant name',
        text1: 'Generate',
        text2: 'Please take a screenshot or take a photo to save this information',
        text3: 'Copy',
        text4: 'Copy succeeded',
        text5: 'Please click',
        placeholder: "Please use the verification code generated by Google's new key"
    },
    pages:{
        homeName: 'Home'
    }
  };