import userApi from './user'
import roleApi from './role'
import menuApi from './menu'
import logApi from './log'
import dictApi from './dict'
import messTemplateApi from './messTemplate'
import messManagementApi from './messManagement'
import routeApi from './route'
import parmsApi from './parms'

export default {
    ...userApi,
    ...roleApi,
    ...menuApi,
    ...logApi,
    ...dictApi,
    ...messTemplateApi,
    ...messManagementApi,
    ...routeApi,
    ...parmsApi
}