import { createRouter, createWebHistory} from 'vue-router'
import routes from './routes' // 导入 router 目录下的 router.js 配置路径和组件的
// import { ElMessage } from 'element-plus' 
import { languagesObj } from '@/config/language.js'
import addRoutes from './addRoutes'
// import { questStore } from '@/store/quest.js'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import website from '@/config/website'
import { configStore } from '@/store/config.js'

let routes_ = routes

if(sessionStorage.getItem('routesList')){//这里有路由
    routes_ = addRoutes()
}
let router = createRouter({
    history: createWebHistory(),
    routes: routes_
})
/**
 * 全局前置路由守卫，每一次路由跳转前都进入这个 beforeEach 函数
 */
router.beforeEach((to, from, next) => {
    NProgress.start();
    if(to.path === '/' && to.query.tenantId){
        sessionStorage.setItem(website.key+'-tenantId',window.atob(to.query.tenantId))
    }
    if (to.path == '/login' ) {
        // 登录或者注册才可以往下进行
        pushLanguage(to,next);
    } else {
        const token = sessionStorage.getItem('Authorization');
        if (token === null || token === '') {
            const lang = to.fullPath.split(/\//)[1];
            const path = to.fullPath.split(/\//)[2];
            if(languagesObj[lang]){
                configStore().setLanguage(lang)
                path === 'login'?next():next(`/${lang}/login`)
            }else{
                const defaultLang = sessionStorage.getItem('lang') || 'en';
                next({path:`/${defaultLang}/login`})
            }
        } else {
            pushLanguage(to,next);
        }
    }
});
router.afterEach(() => {
    NProgress.done();
});
// const recordMenu =  (to)=>{
//     const meta = to.meta || {}
//     const value = to.query.src || to.fullPath
//     const label = to.query.name || to.meta.name
//     if (meta.isTab) {
//         questStore().setTagList({
//             label: label,
//             value: value,
//             name: to.meta.id
//         })
//     }
// }
const pushLanguage = (e,next)=>{
    const lang = e.fullPath.split(/\//)[1];
    const defaultLang = sessionStorage.getItem('lang') || 'en';
    if(languagesObj[lang] ){
        if(lang === defaultLang){
            next()
        }else{
            const paths = e.fullPath.split(/\//)[2];
            next(`/${lang}/${paths}`)
        }
    }else{
        next({path:`/${defaultLang}${e.fullPath}`})
    }
}
export default router;