import loginApi from './login'
import mainApi from './main'
import adminApi from './admin/index'
import utilApi from './util'
import platformApi from './platform'
import coinApi from './coin'
import nftApi from './nft'
import cryptocurrencyApi from './cryptocurrency'
import recordApi from './record'
import monitorEarlyWarningApi from './monitor-early-warning'

export default {
    ...loginApi,
    ...mainApi,
    ...adminApi,
    ...utilApi,
    ...platformApi,
    ...coinApi,
    ...cryptocurrencyApi,
    ...nftApi,
    ...recordApi,
    ...monitorEarlyWarningApi
}