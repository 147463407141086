import request from '@/utils/axios'

const monitorAlarm = (data)=>{
    data.pageIndex = data.current
    data.pageSize = data.size
    return request({
        url: '/dms/cms/monitor_alarm/page',
        method: 'post',
        data
    })
}
const monitorAlarmEdit = (data)=>{
    return request({
        url: '/dms/cms/monitor_alarm/edit',
        method: 'post',
        data
    })
}
const monitorAlarmEditNew = (data)=>{
    return request({
        url: '/dms/cms/monitor_alarm/switch/edit',
        method: 'post',
        data
    })
}
const monitorAlarmDetail = (monitor_code)=>{
    return request({
        url: '/dms/cms/monitor_alarm/detail/'+monitor_code,
        method: 'post',
    })
}
export default{
    monitorAlarm,monitorAlarmEdit,monitorAlarmDetail,monitorAlarmEditNew
}