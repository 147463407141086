import request from '@/utils/axios'

const treeApi = () => {//获得部门
    return request({
        url: '/admin/dept/tree',
        method: 'get'
    })
}
const roleListApi = () => {//获得角色列表
    return request({
        url: '/admin/role/list',
        method: 'get'
    })
}
const postListApi = () => {//获得部门列表 
    return request({
        url: '/admin/post/list',
        method: 'get'
    })
}
const adminUser = ( dataObj) => {//获取用户列表
    return request({
        url: '/admin/user/page',
        method: 'get',
        params: dataObj
    })
}
const addUser = ( dataObj) => {//添加用户
    return request({
        url: '/admin/user',
        method: 'post',
        data: dataObj
    })
}
const judgeUserName = (id) => {//判断用户名是否已经被注册了
    return request({
        url: '/admin/user/details/' + id,
        method: 'get'
    })
}
const delUser = (id) => {//删除用户
    return request({
        url: '/admin/user/'+id,
        method: 'DELETE',
    })
}
const updateUser = (obj) => {//修改用户
    return request({
        url: '/admin/user',
        method: 'put',
        data: obj
    })
}
const updateUser1 = (obj) => {//修改用户
    return request({
        url: '/admin/user/edit',
        method: 'put',
        data: obj
    })
}

const getUserInfo = (username,access_token)=>{//获得用户信息
    return request({
        url: '/admin/user/details/' + username,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + access_token
        }
    })
}
const getKeyByGoogle = (username,access_token)=>{//获取密钥接口
    return request({
        url: '/admin/user/getNewGoogleCode/' + username,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + access_token
        }
    })
}
const updateInfoPassword = (data,access_token)=>{//重置密码
    return request({
        url: 'admin/user/edit',
        method: 'put',
        data:data,
        headers: {
            Authorization: 'Bearer ' + access_token
        }
    })
}
const resetGoogleCode = (username)=>{//重置密钥接口
    return request({
        url: 'admin/user/resetGoogleCode/'+username,
        method: 'get'
    })
}
const getButtonInfo = () => {//获得用户的按钮权限
    return request({
        url: '/admin/user/info',
        method: 'get'
    })
}
export default{
    treeApi,adminUser,
    roleListApi,postListApi,addUser,judgeUserName,
    delUser,updateUser,getUserInfo,getKeyByGoogle,
    updateInfoPassword,resetGoogleCode,getButtonInfo,
    updateUser1
}