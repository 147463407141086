import request from '@/utils/axios'

const getNftiteminfo = (params)=>{
    return request({
        url: '/assets/nftiteminfo/pageByMer',
        method: 'get',
        params: params
    })
}
const getPageByGroup = (params)=>{
    return request({
        url: '/assets/nftiteminfo/pageByGroup',
        method: 'get',
        params: params
    })
}
const addNftiteminfo = (data,code)=>{
    return request({
        url: '/assets/nftiteminfo/' + code,
        method: 'post',
        headers:{
            'Content-Type':'multipart/form-data'
        },
        data: data
    })
}
export default{
    addNftiteminfo,getNftiteminfo,getPageByGroup
}