export default{
    currencySet:{
        name1:'商戶',
        name2:'App名稱',
        name3:'鏈/協議/幣種',
        name4:'合約地址',
        name5:'直接歸集水位線',
        name6:'熱錢包裝載上限',
        name7:'熱錢包預警閾值',
        name8:'鏈費錢包預警閾值',
        name9:'最終冷錢包裝載上限',
        name10:'最小歸集數量',
        name11:'提現限制',
        name11_1:'開啟',
        name11_2:'禁止',
        _name11:'歸集狀態',
        _name11_1:'歸集',
        _name11_2:'不歸集',
        name12:'最小提現數量',
        name13:'單筆提現上限',
        name14:'單日提現上限',
        name15:'日間時間段',
        name16:'開始時間',
        name17:'結束時間',
        name18:'日間單筆免審額度',
        name19:'日間單客戶提現觸發審核總次數',
        name20:'日間單客戶提現觸發審核總額度',
        name21:'日間所有客戶提現觸發審核總額度',
        name22:'夜間時間段',
        name23:'夜間開始時段',
        name24:'夜間結束時段',
        name25:'夜間單筆免審額度',
        name26:'夜間單客戶提現觸發審核總次數',
        name27:'夜間單客戶提現觸發審核總額度',
        name28:'夜間所有客戶提現觸發審核總額度',
        name29:'Google 驗證碼',
        name30:'選擇鏈/協議/幣',
        name31:'選擇',

        rule1:'請輸入大於0的數',
        text1:'幣種名稱',
        text2:'幣種類型',
        text3:'幣',
        text4:'代幣',
        text5:'鏈/協議',
        text6:'充值文案',
        text7:'提幣文案',
        text8:'簡體中文',
        text9:'繁體中文',
        text10:'鏈',
        text10_1:'幣',
        text11:'協議',
        text12:'狀態',
        text13:'創建時間',
        text14:'最後使用時間',
        text15:'操作人',
        text16:'文案設置'//currencySet
    },
    otherWallat:{
        name1:'錢包名稱',
        name2:'優先級分組',
        name3:'餘額',
        name4:'最後修改時間',
        name5:'數量',
        name6:'預警閾值',

        text1:'開始時間',
        text2:'結束時間',
        text3:'錢包地址選擇',
        text4:'新地址',
        text5:'已有地址',
        text6:'錢包地址',
    },
    withdraw:{
        name1:'目標地址',
        name2:'提現理由',
        name3:'提現數量',
        name4:'提現ID',
        name5:'錢包類型',
        name6:'鏈費',
        name7:'提現地址',
        name8:'申請人',
        name9:'審核人',
        name10:'審核理由',
        name11:'提現時間',
        name12:'審核時間',
        name12_1:'待審核',
        name12_2:'審核通過',
        name12_3:'審核拒絕',
        name13:'審核操作',

        text1:'重新審核',
        text2:'審核通過',
        text3:'審核拒絕',
        text4:'出入金熱錢包',
        text5:'鏈費錢包',
        text6:'鏈/幣種/錢包',

        paragraph1:'確認要重新申請嗎？重新申請會自動生成一條新的申請記錄',
        paragraph2:'確認要拒絕審核嗎？請填寫拒絕原因（必填）',
        paragraph3:'確認要通過審核嗎？請填寫通過原因（選填）',
        paragraph4:'重新申請成功，你的新申請記錄ID為：',
        paragraph5: "請填寫審核原因",
        paragraph6: "請選擇前置條件",
    },
    withdraw1:{
        name1:'審核設置',
        paragraph1:'關閉審核後，客戶提現將無需審核',
        paragraph2:'開啟審核後，客戶提現將打開審核',
    }
}