import { createApp } from 'vue'
import '@/assets/css/init.css'
import '@/assets/scss/viewStyle/index.scss';//模块化样式
import "@/assets/scss/font.scss"; //第三方字体样式
import '@/config/size.js'
import App from './App.vue'
const app = createApp(App)

//底层事件绑定：目前只用在监听下拉框的懒加载。
// app.directive('lazyData',(el,binding)=> {
//     console.log(el)
//     let SELECT_DOM = el.querySelector(
//         ".el-select-dropdown .el-select-dropdown__wrap"
//     );
//     SELECT_DOM.addEventListener("scroll", function () {
//         let condition =
//             this.scrollHeight - this.scrollTop <= this.clientHeight;
//         if (condition) {
//             binding.value();
//         }
//     })
// })

import il8n from '@/assets/language/index.js';//国际化
app.use(il8n)

import router from './router/index.js';//动态路由
app.use(router)

import 'element-plus/dist/index.css';//eleUI组件
import ElementPlus from 'element-plus';
// import zhCn from 'element-plus/dist/locale/zh-cn.mjs'


app.use(ElementPlus)
import {iconfontUrl} from '@/config/env'
import {loadStyle} from './utils'
// 动态加载阿里云字体库
iconfontUrl.forEach(ele => {
    loadStyle(ele)
})
import Avue from '@smallwei/avue';//AvueUI组件
// import zhLocale from '@smallwei/avue/lib/locale/lang/zh'
import '@smallwei/avue/lib/index.css';
app.use(Avue)

// 注册全局样式
import './assets/scss/common.scss'


import { createPinia } from 'pinia';//数据存储
const pinia = createPinia()
app.use(pinia)

app.mount('#app') 
 