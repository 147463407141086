import request from '@/utils/axios'
import moment from "moment";
import utilFun from '../util'

const getCurrencySetList = (params) => {//分页查询
    let url = '/assets/assetstenantwalletconfig/page'
    params = utilFun.bindParm(params)
    if(params && params.createTime && params.createTime.length > 0){
        let sendTimeS = moment(params.createTime[0]).format("YYYY-MM-DD HH:ss:mm")   	
        let sendTimeE = moment(params.createTime[1]).format("YYYY-MM-DD HH:ss:mm")   	
        url = url + '?createTimes='+ sendTimeS+'&createTimes='+ sendTimeE;
        delete params.createTime
    }
    if(params && params.chain_protocol && params.chain_protocol.length > 0){
        params.chain = params.chain_protocol[0]
        params.protocol = params.chain_protocol[1]
        delete params.chain_protocol
    }
    return request({
        url: url,
        method: 'get',
        params: params
    })
}
const addCurrencyShopObj = (data,code)=>{
    return request({
        url: '/assets/assetstenantwalletconfig/'+code,
        // url: '/assets/assetstenantwalletconfig',
        method: 'post',
        data: data
    })
}
const updateCurrencyShopObj = (data,code)=>{
    return request({
        // url: '/assets/assetstenantwalletconfig',
        url: '/assets/assetstenantwalletconfig/'+code,
        method: 'put',
        data: data
    })
}
const delCurrencyShopObj = (id)=>{
    return request({
        url: '/assets/assetstenantwalletconfig/' + id,
        // url: '/assets/assetstenantwalletconfig/'+code,
        method: 'DELETE'
    })
}

const getChaincoindescriptionList = (params)=>{
    return request({
        url: '/assets/chaincoindescription/page',
        method: 'get',
        params: params
    })
}
const addChaincoindescriptionObj = (data)=>{
    return request({
        url: '/assets/chaincoindescription',
        method: 'post',
        data: data
    })
}
const updateChaincoindescriptionObj = (data)=>{
    return request({
        url: '/assets/chaincoindescription',
        method: 'put',
        data: data
    })
}
const addWithdrawCoinExemption = (data)=>{//添加提币免审核
    return request({
        url: '/assets/cms/withdraw_coin_exemption/add',
        method: 'post',
        data: data
    })
}
const editWithdrawCoinExemption = (data)=>{//添加提币免审核
    return request({
        url: '/assets/cms/withdraw_coin_exemption/edit',
        method: 'post',
        data: data
    })
}
const pageWithdrawCoinExemption = (data)=>{//获取提币免审核列表
    return request({
        url: '/assets/cms/withdraw_coin_exemption/page',
        method: 'post',
        data: data
    })
}
const delWithdrawCoinExemption = (id)=>{//获取提币免审核列表
    return request({
        url: '/assets/cms/withdraw_coin_exemption/del/'+id,
        method: 'DELETE',
    })
}
export default{
    getCurrencySetList,addCurrencyShopObj,updateCurrencyShopObj,editWithdrawCoinExemption,
    delCurrencyShopObj,getChaincoindescriptionList,addChaincoindescriptionObj,
    updateChaincoindescriptionObj,addWithdrawCoinExemption,pageWithdrawCoinExemption,delWithdrawCoinExemption
}