export default {
    deptFrom:{
        text1: '上级部门',
        text2: '部门名称',
        text3: '排序',
        text4: '创建时间',
        text5: '操作',
        rules1: '同名部门已存在',
        rules2: '根部门',
    },
    inface:{
        text1: '查看 Secret',
        text2: '重置 Secret',
        text3: '商户名称',
        text4: 'App名称',
        text5: 'Key 和 secret',
        text6: '生成',
        text7: '充值回调地址',
        text8: '提取回调地址',
        text9: 'NFT Deploy 合约回调地址',
        text10: 'NFT Mint回调地址',
        text11: 'NFT 充值回调地址',
        text12: 'NFT 提现回调地址',
        text12_1: '准入 IP',
        text13: 'Goole 验证码',
        text14: '状态',
        text15: 'IP地址',
        text15_1: '打开',
        text15_2: '关闭',
        text16: '创建时间',
        text17: '修改时间',
        text18: '操作人',
        text19: '最后使用时间',
        text19_1: '开启',
        text19_2: '禁止',
        text20:'白名单限制',
        rules1:'请填写 IP 地址',
        text21:'（多个 ip 用半角逗号隔开，例如：1.2.3.4,4.5.6.7；最多支持 20 个 ip，此 IP 地址请联系 IT 人员索取）',
        text22:'（可配多组，逗号隔开。例如：1.2.3,1.2.3.4）',
    },
    notice:{
        text1: '商户名称',
        text2: 'APP名称',
        text3: '用户名',
        text4: '低水位预警',
        text5: '短信提醒',
        text6: '邮箱提醒',
        text7: 'Telegram 提醒',
        text8: '操作人',
        text9: '手机号',
        text10: '邮箱',
        text11: '语言设置',

        open_: '关闭',
        close_: '开启'
    },
    role:{
        text1: '权限',
        text2: '分配权限',
    },
    tenant:{
        text1:'序号',
        text2:'商户id',
        text3:'商户名称',
        text4:'商户编号',
        text5:'商户域名',
        text6:'开始时间',
        text7:'结束时间',
        text8:'状态',

        rules1: '同名商户标识已存在',
        rules2: '域名已存在',
        rules3: '长度在 3 到 32 个字符',

        name1:'地址',
        name2:'简介',
    },
    use:{
        name1:'请求接口',
        name2:'请求时间',
        name3:'响应时间',
        
        text1: '详细记录',
        text2: '商户名称',
        text3: 'APP名称',
        text4: '时间',
        text5: '调用状态',
        text6: '操作IP',
        text7: '操作用户',
        text8: '调用总次数',
        text9: '开始时间',
        text10: '结束时间',
        text11: '操作人',
    },
    user:{
        text1:'序号',
        text2:'用户名',
        text3:'密码',
        text5:'姓名',
        text6:'区号',
        text7:'手机号',
        text8:'所属部门',
        text9:'角色',
        text10:'状态',
        text10_1:'有效',
        text10_2:'锁定',
        text11:'邮箱',
        text12:'创建时间',

        rules1:'用户名支持小写英文、数字、中文',
        rules2:'用户名已经存在',
        rules3:'长度在 3 到 20 个字符',
        rules4:'长度在 6 到 20 个字符',
        rules5:'长度在 11 个字符',
    }
}