export default{
    currencySetNft:{
        name1: 'Merchant',
        name2: 'App Name',
        name3: 'Chain/Native Currency',
        name4: 'Wallet Name',
        name5: 'Priority',
        name6: 'Created Time',
        name7: 'Edited time',
        name8: 'Last Edited By',
        name9: 'Status',
        name10: 'Native Currency',
        name11_1: 'On',
        name11_2: 'Off',
        name29: 'Google Code',
        text1: 'Collection',
        text2: 'Withdrawal',
        text3: 'Add',
        text4: 'Edit',
        text5: 'NFT History',
        text6: 'Chain',
        text7: 'Protocol',
        text8: 'Operation',
    },
    otherWallatNft:{
        name1: 'Wallet Name',
        name2: 'Priority',
        name3: 'Deposit Copy',
        name4: 'Withdrawal Copy',
        name5: 'Chain/Protocol/Contract',
        text3: 'Select Wallet Address',
        text4: 'New Address',
        text5: 'Old Address',
        text6: 'Wallet Address',
        text1: 'Charge Copy',
        text2: 'Copy setting',
        reles1: 'Numbers',
        reles2: 'Numbers than 1',
    },
    withdrawNft:{
        name1: 'To Address',
        name2: 'Withdrawal Type',
        name3: 'Token',
        name4: 'Withdrawal Reason',
        name5: 'Withdrawal Quantity',
        name6: 'Rereview',
        name7: 'Review Passed',
        name8: 'Review Reject',
        name9: 'Withdrawal ID',
        name10: 'Chain/Protocol',
        name11: 'Item Name',
        name12: 'Fee',
        name13: 'From Address',
        name14: 'Applicant',
        name15: 'Reviewer',
        name16: 'Review Reason',
        name17: 'Review Time',
        name18: 'Review Operation',
        name18_1: 'Waitting',
        name18_2: 'Pass',
        name18_3: 'Reject',
        text1: 'Add',
        text2: 'Rereview',
    },
    nfts:{
        text1: 'Chain/Protocol',
        text2: 'Select Address',
        text3:'Please select the front item',
        text4:'Are you sure you want to enable the collection function? ',
        text5: 'Only after it is enabled, can NFT deposit and withdrawal wallets be added. ',
        text6:"After it is turned on, the user's NFT will be automatically collected into your NFT deposit and withdrawal hot wallet. ",
        text7: 'General switch',
        text8: 'Off',
        text9: 'On',
        text10: 'Please set the collection strategy for each APP',
        text11: 'Chain/Currency/Wallet',
        text12: 'Collection/Item',
        text13: 'Collection Name',
        text14: 'Withdrawal Time',
        text15: 'Review Settings',
        text16: 'New Collection',
        text161:'New Item',
        text17: 'Details',
        text18: 'Max Size: 5MB',
        text19: 'Type: png, jpg, jpeg',
        paragraph1: "Review reason",
        paragraph2: "Precondition",
        name1: 'Contract Address',
        name2: 'Description',
        name3: 'Owner Type',
        name4: 'Holder Type',
        name5: 'Holder Address',
        name6: 'Suffix',
        name7: 'Browser URL',
        name81: 'Holder',
        name8: 'Merchant',
        name9: 'User',
        name10: 'Item',
        name11: 'Item name',
        name12: 'Item Description',
        name13: 'Owner Address',
        name14: 'Contract Name',
        name15: 'Collection Cover Picture',
        name16: 'Max Number of Item',
        name17: 'Creator Address',
        name18: 'Creation time',
        name19: 'Quantity',
        name20: 'Collection Full Name',
        name21: 'Collection Abbreviation',
        name22: 'Series cover',
        relus1: 'Size:',
        relus2: 'Max Size: 5MB',
        relus3: 'Type:',
        
    },
    news: {
        name1:'Add successfully',
        name2:'View Mint progress',
        name3:'Chain/Currency',
        name4:'Please go to the records inquiry',
        name5:'Attribute',
        name6:'Type',
        name7:'Name',

        text1:'Simplified Chinese',
        text2:'Traditional Chinese',
        text3:'English',
    }   
}