import request from '@/utils/axios'

const getNoticeList = (query) => {
    return request({
        url: '/message/usernoticeconfig/page',
        method: 'get',
        params: query
    })
}
const addNoticeObj = (obj) => {
    return request({
        url: '/message/usernoticeconfig',
        method: 'post',
        data: obj,
    })
}
const updateNoticeObj = (obj) => {
    return request({
        url: '/message/usernoticeconfig',
        method: 'put',
        data: obj,
    })
}

export default{
    getNoticeList,addNoticeObj,updateNoticeObj
}