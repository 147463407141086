import merchantWalletApi from './merchantWallet'
import merchantWalletNftApi from './merchantWalletNft'
import customerWalletApi from './customerWallet'
import customerWalletNftApi from './customerWalletNft'

export default {
    ...merchantWalletApi,
    ...merchantWalletNftApi,
    ...customerWalletApi,
    ...customerWalletNftApi
}