import request from '@/utils/axios'
import { parmFun } from '@/utils/record'
 
const getTopUp = (params) => {//商户充值记录
    parmFun(params)
    return request({
        url: '/assets/assetsdepositrecord/pageByMer',
        method: 'get',
        params: params
    })
}
const pageByMer = (params) => {//商户提现记录
    parmFun(params)
    return request({
        url: '/assets/withdrawapply/pageByMer',
        method: 'get',
        params: params
    })
}
const getAggregationList = (params) => {//商户归集记录
    parmFun(params)
    return request({
        url: '/assets/colletionrecord/pageByUser',
        method: 'get',
        params: params
    })
}
const getWalletWater = (params) => {//商户归集记录
    parmFun(params)
    return request({
        url: '/assets/walletchangelog/pageByMer',
        method: 'get',
        params: params
    })
}
const getAccountWater = (params) => {//帐户流水
    parmFun(params)
    return request({
        url: '/assets/accountchangelog/pageByMer',
        method: 'get',
        params: params
    })
}
const getBalanceWallet = (params) => {//钱包余额
    parmFun(params)
    return request({
        url: '/assets/assetswalletinfo/pageByMer',
        method: 'get',
        params: params
    })
}
const getBbalanceAccount = (params) => {//账户余额
    parmFun(params)
    return request({
        url: '/assets/assetsaccountinfo/pageByMer',
        method: 'get',
        params: params
    })
}
const getAccountTime = (params) => {//商户冻结
    parmFun(params)
    return request({
        url: '/assets/walletfrozen/pageByMer',
        method: 'get',
        params: params
    })
}

export default{
    getTopUp,pageByMer,getAggregationList,getWalletWater,getBbalanceAccount,
    getBalanceWallet,getAccountWater,getAccountTime
}