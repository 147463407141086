export default {
    getMenu:{
        loadingMenu:'获取菜单中。。。'
    },
    login: {
        tit:'钱包管理后台',
        language:'语言',
        input1:'账号',
        input2:'密码',
        input3:'Google 验证码',
        button: '登录',
        text1:'请输入账号',
        text2:'请输入密码',
        text3:'请输入 Google 验证码',
        tit2: '让加密支付变得简单',
        tit3: '安全。合规。易于使用。',
    },
    homePage:{
        'wel1':'欢迎使用 企业钱包',
        'wel2':'管理后台',
    },
    button: {
        search: '搜索',
        add: '添加',
        del: '删除',
        update: '编辑',
        enter: '确定',
        close: '取消',
        imports: '导出',
        authority: '权限',
        newer: '更新',
        cache:'缓存',
        dictionary:'字典项',
        refresh:'刷新',
        open_:'开启',
        close_:"关闭",
        moreDel: '批量删除',
        tijiao:'提交',
        closeQ: '关闭',
        look:'查看',
        writes:'充提文案',
        more:'更多',
        compla:'完成',
        remove:'重置',
        imports1:'导入',
        checks:'选择',
        operation:'操作',
        closeq1:'关闭其他',
        upload:'上传',
        getLine: '链接'

    },
    inputTit:{
        shuru:'请输入',
        xuanze:'请选择',
        shangchuan:'请上传',
        isNull:'不能为空',
    },
    alert:{
        selEnter: '是否确认删除?',
        succDel: '删除成功',
        succUpdate: '修改成功',
        succAdd: '添加成功',
        succSync: '同步成功',
        succSync_: '重置成功',
        succBind: '操作成功',

        delText:'删除失败',
        succOpen:'启用成功',
        succOff:'禁用成功'
    },
    tyle_:{
        warn: '警告',
        tits: '提示',
        sycc: '成功'
    },
    downName:{
        name_1: '消息模板',
        name_2: '日志模板'
    },
    topText:{
        text1: '钱包管理',
        text2: '首页',
        text3: '个人信息',
        text4: '退出',
    },
    searchText:{
        text1: '请填写',
        text2: '请选择',
        text3: '到',
        text4: '开始时间',
        text5: '结束时间',
    },
    importss:{
        text1:'消息模板导入',
        text2:'将文件拖到此处，或',
        text3:'点击上传',
        text4:'仅允许导入xls、xlsx格式文件。',
        text5:'下载模板',
    },
    switchGoogle:{
        name1:'更换密码',
        name2:'新密码',
        name3:'旧密码',
        name4:'Google新密钥',
        name5:'Google验证码',
        name6:'温馨提示',
        name7:'管理员',
        name8:'商户名',

        text1:'生成',
        text2:'请务必截屏或者拍照保存这些信息',
        text3:'复制',
        text4:'复制成功',
        text5:'请点击',

        placeholder: '请使用Google新密钥生成的验证码'
    },
    pages:{
        homeName: '首页'
    }
};