import request from '@/utils/axios'
import { parmFun } from '@/utils/record'

const getTopUpUserNFT = (params) => {//商户充值记录
    parmFun(params)
    return request({
        url: '/assets/assetsdepositrecord/pageByUserNFT',
        method: 'get',
        params: params
    })
}
const getTopUpUserNFTCallback = (recordId) => {//商户充值记录
    return request({
        url: `/assets/assetsdepositrecord/nftCallback/${recordId}`,
        method: 'get',
    })
}
const pageByMerUserNFT = (params) => {//商户提现记录
    parmFun(params)
    return request({
        url: '/assets/withdrawapply/pageByUserNFT',
        method: 'get',
        params: params
    })
}
const pageByMerUserNFTCallback = (applyId) => {//商户提现记录
    return request({
        url: `/assets/withdrawapply/nftCallback/${applyId}`,
        method: 'post',
    })
}
const getAggregationListUserNFT = (params) => {//商户归集记录
    parmFun(params)
    return request({
        url: '/assets/colletionrecord/pageByUserNFT',
        method: 'get',
        params: params
    })
}
const getWalletWaterUserNFT = (params) => {//商户归集记录
    parmFun(params)
    return request({
        url: '/assets/walletchangelog/pageByUserNFT',
        method: 'get',
        params: params
    })
}
const getAccountWaterUserNFT = (params) => {//帐户流水
    parmFun(params)
    return request({
        url: '/assets/accountchangelog/pageByUserNFT',
        method: 'get',
        params: params
    })
}
const getBalanceWalletUserNFT = (params) => {//钱包余额
    parmFun(params)
    return request({
        url: '/assets/assetswalletinfo/pageByUserNFT',
        method: 'get',
        params: params
    })
}
const getBbalanceAccountUserNFT = (params) => {//账户余额
    parmFun(params)
    return request({
        url: '/assets/assetsaccountinfo/pageByUserNFT',
        method: 'get',
        params: params
    })
}

export default{
    getTopUpUserNFT,pageByMerUserNFT,getAggregationListUserNFT,getWalletWaterUserNFT,getBbalanceAccountUserNFT,
    getBalanceWalletUserNFT,getAccountWaterUserNFT,getTopUpUserNFTCallback,pageByMerUserNFTCallback
}