
// import moment from "moment";
const changeType = [
    { label: '充值', value: 1 },
    { label: '提币', value: 2},
    { label: '归集', value: 3 },
    { label: '打链费', value: 4 },
    { label: '链费入金', value: 5 },
    { label: '链费出金', value: 6 },
    { label: '打链费实际消耗的手续费', value: 7 },
    { label: '归集实际消耗的手续费', value: 8 },
    { label: '归集入金', value: 9 },
    { label: '归集出金', value: 10 },
    { label: '提币冻结', value: 11 },
    { label: '提币冻结链费', value: 12 },
    { label: '提币实际消耗的手续费', value: 13 },
    { label: '客户提现出金', value: 14 },
    { label: '商户提现出金', value: 15 },
    { label: '归集出金冻结', value: 16 },
    { label: '归集出金解冻', value: 17 },
]

const relationType = [
    { label: '充值', value: 1 },
    {label: '提币',value:2},
    {label: '归集',value:3},
    {label: '打链费',value:4}
]
const walletStyle = [
    { label: '客户钱包', value: 0 },
    { label: '出入金热钱包', value: 1 },
    {label: '链费钱包',value: 2},
    {label: '冷钱包',value: 3},
    {label: 'NFT出入金热钱包',value: 4},
    {label: 'NFT链费钱包',value: 5},
]
const frozenType = [
    { label: '提币数量', value: 1 },
    { label: '提币手续费', value: 2 },
    {label: '归集数量',value: 3},
    {label: '归集手续费',value: 4},
    {label: 'NFT deploy手续费',value: 5},
    {label: 'NFT mint手续费',value: 6},
    {label: 'NFT mint手续费',value: 7},
]
const statusType = [
    { label: '未处理', value: 0 },
    { label: '待确认', value: 1 },
    { label: '已确认', value: 2 },
    {label: '确认失败',value: 3}
]
const collectionFeeStatus = [
    { label: '无需归集', value: -1 },
    { label: '未打链费', value: 0 },
    {label: '已打链费',value: 1},
    {label: '链费充足',value: 2},
    {label: '链费处理中',value: 3},
]
const collectionStatus = [
    { label: '无需归集', value: -1 },
    { label: '未归集', value: 0 },
    {label: '归集中',value: 1},
    {label: '已归集',value: 2},
    {label: '归集失败',value: 3},
]
const callbackStatus = [
    { label: '无需回调', value: -1 },
    { label: '未回调', value: 0 },
    {label: '已回调',value: 1},
    {label: '回调处理中',value: 2},
]
const withdrawStatus = [
    { label: '未处理', value: 0 },
    { label: '待确认', value: 1 },
    {label: '已确认',value: 2},
    {label: '确认失败',value: 3}
]
const parmFun = (params)=>{
    if(params.confirmTime){
        params.confirmTimeStart = params.confirmTime[0];
        params.confirmTimeEnd =  params.confirmTime[1]
        delete params.confirmTime
    }
    if(params.applyTime){
        params.applyTimeStart = params.applyTime[0];
        params.applyTimeEnd = params.applyTime[1]
        delete params.applyTime
    }
    if(params.createTime){
        params.createTimeStart = params.createTime[0]
        params.createTimeEnd = params.createTime[1]
        delete params.createTime
    }
    if(params.updateTime){
        params.updateTimeStart = params.updateTime[0]
        params.updateTimeEnd = params.updateTime[1]
        delete params.updateTime
    }
    if(params.withdrawTime){
        params.withdrawTimeStart = params.withdrawTime[0]
        params.withdrawTimeEnd = params.withdrawTime[1]
        delete params.withdrawTime
    }
    if(params.unfreezeTime){
        params.unfreezeTimeStart = params.unfreezeTime[0]
        params.unfreezeTimeEnd = params.unfreezeTime[1]
        delete params.unfreezeTime
    }
    if(params.callbackTime){
        params.callbackTimeStart = params.callbackTime[0]
        params.callbackTimeEnd = params.callbackTime[1]
        delete params.callbackTime
    }
}
export {
    changeType,relationType,walletStyle,frozenType,statusType,
    collectionStatus,collectionFeeStatus,callbackStatus,
    parmFun,withdrawStatus
}