import website from '@/config/website'
import qs from 'qs'
import request from '@/utils/axios'

const loginByUsername = (username, password, code, randomStr) => {//登录
    let grant_type = 'password'
    let dataObj = qs.stringify({'username': username, 'password': password})
    let basicAuth = 'Basic ' + window.btoa(website.formLoginClient)
    return request({
        url: '/auth/oauth/token',
        headers: {
            username: username,
            'TENANT-ID': '1',
            'Authorization': basicAuth

        },
        method: 'post',
        params: {randomStr, code, grant_type},
        data: dataObj
    })
}
const logoutByUsername = () => {//退出
    return request({
        url: '/auth/token/logout',
        method: 'DELETE'
    })
}
export default{
    loginByUsername,
    logoutByUsername
}