export default {
    adminMenu: {
        name1:'菜單名稱',
        name2:'圖標',
        name3:'排序',
        name4:'組件路徑',
        name5:'類型',
        name5_1: '菜單',
        name5_2: '頂菜單',
        name5_3: '按鈕',
        name6:'緩衝',
        name6_1:'關閉',
        name6_2:'開啟',
        name7:'權限標識',
        name8:'操作',
        name9:'上級菜單',
        name10:'路由緩衝',
        name10_1:'開啟',
        name10_2:'關閉',
        quanxian: '權限標識',
        caozuo: '操作'
    },
    adminRole:{
        name1:'序號',
        name2:'角色名稱',
        name3:'角色標識',
        name4:'角色描述',
        name5:'數據權限',
        name5_1:'全部',
        name5_2:'本級及子級',
        name5_3:'本級',
        name6:'創建時間',
        rules1: '同名角色標識已存在',
        rules2: '同名角色已存在',
        rules3: '長度在 3 到 20 個字符',
        rules4: '長度在 128 個字符內',
    },
    adminDict:{
        name1:'序號',
        name2:'類型',
        name3:'描述',
        name4:'字典類型',
        name5:'備註信息',
        name6:'創建時間',
        name7:'數據值',
        name8:'標籤名',
        name9:'排序',

        text1:'新增',
        text2:'修改',
        text3:'字典項',

        rules1: '同名字典已存在',
        rules2: '長度在 30 個字符內'
    },
    adminParms:{
        name1:'序號',
        name2:'名稱',
        name3:'鍵',
        name4:'值',
        name5:'編碼',
        name6:'類型',
        name7:'狀態',
        name8:'類別',

        rules1: '參數鍵已經存在',
        rules2: '長度在 30 個字符'
    },
    adminRoute:{
        tit:'路由配置是非常專業的事情，不建議非工程師操作'
    },
    adminMessM:{
        name1:'序號',
        name2:'用戶ID',
        name3:'App名稱',
        name4:'來自',
        name5:'內容',
        name6:'發送類型',
        name6_1:'短信',
        name6_2:'郵件',
        name7:'發送場景',
        name8:'發送時間',
        name9:'回執時間',
        name10:'回執狀態',
        name10_1:'已發送',
        name10_2:'未發送',
        name11:'操作人',
        serch1:'用戶'
    },
    adminMessT:{
        name1:'序號',
        name2:'語言代碼',
        name2_1:'中文',
        name2_2:'英文',
        name2_3:'繁體中文',
        name3:'內容',
        name4:'發送場景',
        name5:'發送平台',
        name5_1:'短信',
        name5_2:'郵件',
        name6:'創建時間',
        name7:'修改時間',
        name8:'操作人',
        name9:'狀態',
        name9_1:'關閉',
        name9_2:'開啟',
    },
    adminSole:{
        name1:'序號',
        name2:'類型',
        name3:'標題',
        name4:'IP地址',
        name5:'請求方式',
        name6:'客戶端',
        name7:'耗時時長',
        name8:'創建時間',
        tit1:'請選擇日誌後進行刪除'
    }
};