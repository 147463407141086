import request from '@/utils/axios'

const dictType = (type) => {//当前字典类型是否已存在
    return request({
        url: '/admin/dict/type/' + type,
        method: 'get'
    })
}
const getDictList = (query) => {//获得字典的列表
    return request({
        url: '/admin/dict/page',
        method: 'get',
        params: query
    })
}
const addDict = (obj) => {//添加字典记录
    return request({
        url: '/admin/dict/',
        method: 'post',
        data: obj
    })
}
const addDictItem = (obj) => {//添加字典记录
    return request({
        url: '/admin/dict/item',
        method: 'post',
        data: obj
    })
}
const updateDict = (obj) => {//修改字典记录
    return request({
        url: '/admin/dict/',
        method: 'put',
        data: obj
    })
}
const updateDictItem = (obj) => {//修改字典记录
    return request({
        url: '/admin/dict/item',
        method: 'put',
        data: obj
    })
}
const delDict = (row) => {//删除
    return request({
        url: '/admin/dict/' + row.id,
        method: 'delete'
    })
}
const delDictitem = (id) => {//删除
    return request({
        url: '/admin/dict/item/' + id,
        method: 'delete'
    })
}
const getDictListItem = (query) => {//获得字典项李彪
    return request({
        url: '/admin/dict/item/page',
        method: 'get',
        params: query
    })
}
const getDictListItemDetail = (query) => {//获得字典项李彪
    return request({
        url: '/admin/sysdictitemname/page',
        method: 'get',
        params: query
    })
}
const refreshCache = () => {//缓存
    return request({
        url: '/admin/dict/sync',
        method: 'put'
    })
}
const getDicType = () => {//类型
    return request({
        url: '/admin/dict/type/dict_type',
        method: 'get'
    })
}
const getStatusType = () => {//类型
    return request({
        url: '/admin/dict/type/status_type',
        method: 'get'
    })
}
const getParamType = () => {//类型
    return request({
        url: '/admin/dict/type/param_type',
        method: 'get'
    })
}


export default{
    dictType,getDictList,addDict,
    updateDict,delDict,getDictListItem,delDictitem,
    addDictItem,updateDictItem,refreshCache,getDicType,
    getStatusType,getParamType,getDictListItemDetail
}