import shopApi from './shop'
import deptApi from '../platform/dept'
import accessApi from '../platform/access'
import noticeApi from './notice'
import useApi from './use'
import parmsApi from './parms'

export default {
    ...shopApi,
    ...deptApi,
    ...accessApi,
    ...noticeApi,
    ...useApi,
    ...parmsApi
}