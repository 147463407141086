export default {
    adminMenu: {
        name1:'菜单名称',
        name2:'图标',
        name3:'排序',
        name4:'组件路径',
        name5:'类型',
        name5_1: '菜单',
        name5_2: '顶菜单',
        name5_3: '按钮',
        name6:'缓冲',
        name6_1:'关闭',
        name6_2:'开启',
        name7:'权限标识',
        name8:'操作',
        name9:'上级菜单',
        name10:'路由缓冲',
        name10_1:'开启',
        name10_2:'关闭',
        quanxian: '权限标识',
        caozuo: '操作'
    },
    adminRole:{
        name1:'序号',
        name2:'角色名称',
        name3:'角色标识',
        name4:'角色描述',
        name5:'数据权限',
        name5_1:'全部',
        name5_2:'本级及子级',
        name5_3:'本级',
        name6:'创建时间',
        rules1: '同名角色标识已存在',
        rules2: '同名角色已存在',
        rules3: '长度在 3 到 20 个字符',
        rules4: '长度在 128 个字符内',
    },
    adminDict:{
        name1:'序号',
        name2:'类型',
        name3:'描述',
        name4:'字典类型',
        name5:'备注信息',
        name6:'创建时间',
        name7:'数据值',
        name8:'标签名',
        name9:'排序',

        text1:'新增',
        text2:'修改',
        text3:'字典项',

        rules1: '同名字典已存在',
        rules2: '长度在 30 个字符内'
    },
    adminParms:{
        name1:'序号',
        name2:'名称',
        name3:'键',
        name4:'值',
        name5:'编码',
        name6:'类型',
        name7:'状态',
        name8:'类别',

        rules1: '参数键已经存在',
        rules2: '长度在 30 个字符'
    },
    adminRoute:{
        tit:'路由配置是非常专业的事情，不建议非工程师操作'
    },
    adminMessM:{
        name1:'序号',
        name2:'商户',
        name3:'App名称',
        name4:'来自',
        name5:'内容',
        name6:'发送类型',
        name6_1:'短信',
        name6_2:'邮件',
        name7:'发送场景',
        name8:'发送时间',
        name9:'回执时间',
        name10:'回执状态',
        name10_1:'已发送',
        name10_2:'未发送',
        name11:'操作人',
        serch1:'用户'
    },
    adminMessT:{
        name1:'序号',
        name2:'语言代码',
        name2_1:'中文',
        name2_2:'英文',
        name2_3:'繁體中文',
        name3:'内容',
        name4:'发送场景',
        name5:'发送平台',
        name5_1:'短信',
        name5_2:'邮件',
        name6:'创建时间',
        name7:'修改时间',
        name8:'操作人',
        name9:'状态',
        name9_1:'关闭',
        name9_2:'开启',
    },
    adminSole:{
        name1:'序号',
        name2:'类型',
        name3:'标题',
        name4:'IP地址',
        name5:'请求方式',
        name6:'客户端',
        name7:'耗时时长',
        name8:'创建时间',
        tit1:'请选择日志后进行删除'
    }
};