import routes from './routes' 

const getMenuList = (menuList)=>{
    let array_ = []
    if(menuList){
        for(let item of menuList){
            let items = {
                path: removeFirstStr(item) ,
                meta: {label:item.label,id:item.id,name:item.name,isTab:true},
            }
            if(item.children && item.children.length > 0){
                items.children = getMenuList(item.children)
            }else{
                items.component = () => import('../views'+item.path);
                items.meta.psths = '../views'+item.path
            }
            array_.push(items)
        }
        return array_
    }else{
        return []
    }
}
const removeFirstStr = (item)=>{//去掉首字母
    if(item.parentId === "-1"){
        return item.path.slice(1) 
    }else{
        return item.path.slice(item.path.split("/")[1].length+2);
    }
}
let routes_ = routes
const addRoutes = ()=>{
    let homePage = {
        path: 'homePage' ,
        component: () => import('@/views/home.vue'),
    }
    for(let item of routes_){
        if(item.children && item.children[0].children){
            let array_ = getMenuList(JSON.parse(sessionStorage.getItem('routesList')))
            array_.push(homePage)
            item.children[0].children = array_
        }
    }
    return routes_
}
export default addRoutes;