import request from '@/utils/axios'
import { parmFun } from '@/utils/record'

const getTopUpUser = (params) => {//商户充值记录
    parmFun(params)
    return request({
        url: '/assets/assetsdepositrecord/pageByUser',
        method: 'get',
        params: params
    })
}
const getTopUpUserCall = (recordId) => {//商户充值回调
    return request({
        url: `/assets/assetsdepositrecord/callback/${recordId}`,
        method: 'get',
    })
}
const pageByMerUser = (params) => {//商户提现记录
    parmFun(params)
    return request({
        url: '/assets/withdrawapply/pageByUser',
        method: 'get',
        params: params
    })
}
const pageByMerUserCall = (applyId) => {//商户提现回调
    return request({
        url: `/assets/withdrawapply/callback/${applyId}`,
        method: 'post',
    })
}
const getAggregationListUser = (params) => {//商户归集记录
    parmFun(params)
    return request({
        url: '/assets/colletionrecord/pageByUser',
        method: 'get',
        params: params
    })
}
const getWalletWaterUser = (params) => {//商户归集记录
    parmFun(params)
    return request({
        url: '/assets/walletchangelog/pageByUser',
        method: 'get',
        params: params
    })
}
const getAccountWaterUser = (params) => {//帐户流水
    parmFun(params)
    return request({
        url: '/assets/accountchangelog/pageByUser',
        method: 'get',
        params: params
    })
}
const getBalanceWalletUser = (params) => {//钱包余额
    parmFun(params)
    return request({
        url: '/assets/assetswalletinfo/pageByUser',
        method: 'get',
        params: params
    })
}
const getBbalanceAccountUser = (params) => {//账户余额
    parmFun(params)
    return request({
        url: '/assets/assetsaccountinfo/pageByUser',
        method: 'get',
        params: params
    })
}
const refreshTx = (data) => {//账户余额
    return request({
        url: '/chain/walletinfo/refreshTx',
        method: 'post',
        data
    })
}

export default{
    getTopUpUser,pageByMerUser,getAggregationListUser,getWalletWaterUser,getBbalanceAccountUser,
    getBalanceWalletUser,getAccountWaterUser,getTopUpUserCall,pageByMerUserCall,refreshTx
}