export default {
    agreement:{
        text1: 'SN',
        text2: 'Chain',
        text3: 'Protocol',
        text4: 'Use Scene',
        text4_1: 'Have Not',
        text5: 'Created Time',
        text6: 'Operator',
        text7: 'Status',
        text7_1: 'Off',
        text7_2: 'On',
    },
    chain:{
        text1: 'SN',
        text2: 'Chain',
        text3: 'Native Currency',
        text4: 'Fast Account Block Confirm',
        text5: 'Security Account Block Confirm',
        text6: 'Gas Tracker API',
        text7: 'Virtual Machine Type',
        text8: 'Token',
        text8_1: 'Have Not',
        text8_2: 'Have',
        text9: 'Created Time',
        text10: 'Edited Time',
        text11: 'Last Edited',
        text12: 'Status',
        text12_1: 'Off',
        text12_2: 'On',
    },
    contra:{
        text1: 'SN',
        text2: 'Use Scene',
        text3: 'Have Not',
        text4: 'Contract Name',
        text5: 'Chain/Protocol',
        text6: 'Contract Type',
        text7: 'Standard',
        text8: 'Contract Content',
        text9: 'Created Time',
        text101: 'Edited Time',
        text10: 'Last Edited',
        text11: 'Status',
        text12: 'Off',
        text13: 'On',
        text14:'Contract Type Name',
        name1: 'Mint Permissions',
            name1_1: 'Owner',
            name1_2: 'Everyone',
        name2: 'User Scenes',
            name2_1: 'Supply',
            name2_2: 'Unlimt',
    },
    curr:{
        text1: 'Currency Name',
        text2: 'Currency Type',
        text3: 'Currency',
        text4: 'Token',
        text5: 'Chain/Protocol',
        text6: 'Label',
        text7: 'Have',
        text8: 'Have Not',
        text9: 'Contract Address',
        text10: 'Decimal Places',
        text11: 'Min Collection Quantity',
        text12: 'Browser url',
        text13: 'Created Time',
        text14: 'Edited Time',
        text15: 'Operator',
        text16: 'Status',
        text17: 'Deposit',
        text18: 'Withdrawal',
        text19: 'Google Code',
        text20: 'Chain',
        reles1: 'Number Than 0',
        reles2: 'Please Input a positive integer',
    }
}