import request from '@/utils/axios'

const parmsList = (query) => {
    return request({
        url: '/admin/param/page',
        method: 'get',
        params: query
    })
}
const addParmsList = (obj) => {
    return request({
        url: '/admin/param',
        method: 'post',
        data: obj
    })
}
const getParmsObj = (key) => {
    return request({
        url: '/admin/param/publicValue/' + key,
        method: 'get'
    })
}
const delParmsObj = (id) => {
    return request({
        url: '/admin/param/' + id,
        method: 'delete'
      })
}
const putParmsObj = (obj) => {
    return request({
        url: '/admin/param',
        method: 'put',
        data: obj
    })
}
const refreshParmsObj = () => {
    return request({
        url: '/admin/param/sync',
        method: 'put'
    })
}

export default{
    parmsList,addParmsList,getParmsObj,
    delParmsObj,putParmsObj,refreshParmsObj
}