export default {
    adminMenu: {
        name1: 'Menu Name',
        name2: 'Icon',
        name3: 'Sort',
        name4: 'Component Path',
        name5: 'Type',
        name5_1: 'Menu',
        name5_2: 'Top Menu',
        name5_3: 'Button',
        name6: 'Buffer',
        name6_1: 'Off',
        name6_2: 'On',
        name7: 'Permission ID',
        name8: 'Operation',
        name9: 'Parent Menu',
        name10: 'Route Buffer',
        name10_1: 'On',
        name10_2: 'Off',
        quanxian: 'Permission Identifier',
        caozuo: 'Operation'
    },
    adminRole:{
        name1: 'SN',
        name2: 'Role',
        name3: 'Role ID',
        name4: 'Role Description',
        name5: 'Data Permission',
        name5_1: 'All',
        name5_2: 'Current level and sub level',
        name5_3: 'Current level',
        name6: 'Created Time',
        rules1: 'Role ID already exists',
        rules2: 'Role already exists',
        rules3: '3 to 20 characters in length',
        rules4: '128 characters in length',
    },
    adminDict:{
        name1: 'SN',
        name2: 'Type',
        name3: 'Description',
        name4: 'Dictionary Type',
        name5: 'Notes',
        name6: 'Created Time',
        name7: 'Data Value',
        name8: 'Tag name',
        name9: 'Sort',

        text1:'Add',
        text2:'Edit',
        text3:'Dictionary item',

        rules1: 'Dictionary already exists'
    },
    adminParms:{
        name1: 'SN',
        name2: 'Name',
        name3: 'Key',
        name4: 'Value',
        name5: 'Code',
        name6: 'Type',
        name7: 'Status',
        name8: 'Category',
        rules1: 'Parameter key already exists',
        rules2: '30 characters in length'
    },
    adminRoute:{
        tit:'Routing configuration is a very professional matter, and it is not recommended for non-engineers to operate'
    },
    adminMessM:{
        name1: 'SN',
        name2: 'User ID',
        name3: 'App Name',
        name4: 'From',
        name5: 'Content',
        name6: 'Type',
        name6_1: 'SMS',
        name6_2: 'Mail',
        name7: 'Sending Scene',
        name8: 'Sending Time',
        name9: 'Receipt Time',
        name10: 'Receipt Status',
        name10_1: 'Sent',
        name10_2: 'Not sent',
        name11: 'Operator',
        serch1: 'User'
    },
    adminMessT:{
        name1: 'SN',
        name2: 'Languages code',
        name2_1: 'Simplified Chinese',
        name2_2: 'English',
        name2_3: 'Traditional Chinese',
        name3: 'Content',
        name4: 'Sending Scene',
        name5: 'Sending Platform',
        name5_1: 'SMS',
        name5_2: 'Mail',
        name6: 'Created Time',
        name7: 'Edited Time',
        name8: 'Operator',
        name9: 'Status',
        name9_1: 'Off',
        name9_2: 'On',
    },
    adminSole:{
        name1: 'SN',
        name2: 'Type',
        name3: 'Title',
        name4: 'IP Address',
        name5: 'Request Method',
        name6: 'Client',
        name7: 'Time consuming time',
        name8: 'Created Time',
        tit1: 'Please select a log to delete'
    }
};